
// Taken from https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export default function deepFreeze(object) {
  Object.freeze(object);

  Object.getOwnPropertyNames(object).forEach(function (prop) {
    if (Object.prototype.hasOwnProperty.call(object, prop)
      && object[prop] !== null
      && (typeof object[prop] === 'object' || typeof object[prop] === 'function')
      && !Object.isFrozen(object[prop])) {
      deepFreeze(object[prop]);
    }
  });

  return object;
}
