import {itemEmpty, itemUpdate} from './common.js'
import Vue from 'vue'

/**
 *
 * @param {object} base
 * @param {object} properties
 * @param {object} apiDefinition
 * @return {{mutations: {}, state: {}, getters: {}, actions: {}, namespaced: boolean, modules}}
 */
export default function editItem (base, properties, apiDefinition) {
  // FIXME: ensure we have needed actions and the like.

  // Reset to initial value.
  const RESET = 'RESET'
  // Set the initial values.
  const SET_ITEM = 'SET_ITEM'
  // Update the violations.
  const SET_VIOLATIONS = 'SET_VIOLATIONS'
  // Update the item.
  const UPDATE_ITEM = 'UPDATE_ITEM'

  // Validate the values against restrictions and return the list of violations.
  const validate = (values) => {
    const violations = {}
    Object.keys(properties).map((propName) => {
      if (required.indexOf(propName) !== -1 && (values[propName] === null || values[propName] === undefined)) {
        violations[propName] = 'This value should not be null.'
      }
    })
    return violations
  }

  const required = []
  Object.keys(properties).map((propName) => {
    const prop = properties[propName]
    if (prop.required) {
      required.push(propName)
    }
  })

  const result = {
    namespaced: true,
    state: {
      ...base.state,
      base: itemEmpty(properties),
      item: itemEmpty(properties),
      violations: null,
    },
    getters: {
      ...base.getters,
      item: state => state.item,
      violations: state => state.violations,
      // FIXME: this is only possible when we have information on props of "input" of put action and "output" of fetch action.
      readOnly: () => (propName) => !Object.keys(properties).some((property) => propName === property),
      required: () => (propName) => required.indexOf(propName) !== -1,
    },
    mutations: {
      ...base.mutations,
      [RESET] (state) {
        Object.assign(state, {
          item: Object.assign({}, state.base),
          violations: null
        })
      },
      [SET_VIOLATIONS] (state, violations) {
        Object.assign(state, { violations })
      },
      [SET_ITEM] (state, item) {
        Object.assign(state, {
          base: Object.assign({}, item),
          item
        })
      },
      [UPDATE_ITEM] (state, updated) {
        itemUpdate(state.item, updated, properties)
      }
    },
    actions: {
      ...base.actions,
      reset ({ commit, dispatch, getters, state }) {
        commit(RESET)
        commit(SET_VIOLATIONS, validate(state.item))
        return dispatch('resetSelections')
          .then(() => {
            return Object.assign({}, getters.item)
          })
      },
      setItem ({ commit, dispatch, getters }, data) {
        commit(SET_ITEM, data)
        commit(SET_VIOLATIONS, validate(data))
        return dispatch('updateSelections')
          .then(() => {
            return Object.assign({}, getters.item)
          })
      },
      updateItem ({ commit, dispatch, getters }, updated) {
        commit(UPDATE_ITEM, updated)
        commit(SET_VIOLATIONS, validate(getters.item))

        return new Promise((resolve, reject) => {
          dispatch('updateSelections')
            .then(() => {
              resolve()
            })
            .catch(() => {
              reject()
            })
        })
      },
      setViolations ({ commit }, violations) {
        commit(SET_VIOLATIONS, violations)

        return Promise.resolve(violations)
      },
      resetSelections () {
        return Promise.resolve()
      },
      updateSelections () {
        return Promise.resolve()
      }
    }
  }

  const referenceNames = []
  const references = {}
  Object.keys(properties).map((propName) => {
    const reference = properties[propName].reference
    if (reference) {
      const resource = apiDefinition.resource[reference]
      if (resource && resource.operations.list && resource.operations.list.filter.some((parameter) => parameter.name === 'orSearch_contains')) {
        referenceNames.push(propName)
        references[propName] = resource
      }
    }
  });

  if (referenceNames.length > 0) {
    const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM_'
    const UPDATE_SEARCH = 'UPDATE_SEARCH_'
    const SET_OPTIONS = 'SET_OPTIONS_'
    result.actions.updateSelections = function ({dispatch, commit, getters}) {
      const promises = []
      referenceNames.map((propName) => {
        const listOperation = references[propName].operations.list
        promises.push(new Promise((resolve, reject) => {
          if (properties[propName].type === 'array') {
            const ids = getters.item[propName]
            // Test if id value is null...
            if (null === ids || undefined === ids) {
              commit(SET_SELECTED_ITEM + propName, null)
              resolve()
              return
            }
            // ... or if selected values are already correct...
            const selected = getters[propName + '_selected']
            if (selected) {
              const tmp = ids.filter((id) => !selected.some(test => id === test['@id']))
              if (tmp.length === 0) {
                resolve()
                return
              }
            }
            // ... or if all are in list of options.
            const newSelected = []
            if (ids.every(id => getters[propName + '_options'].some((item) => {
              if (item['@id'] === id) {
                newSelected.push(item)
                return true
              }
            }))) {
              commit(SET_SELECTED_ITEM + propName, newSelected)
              resolve()
              return
            }

            throw new Error('Collection was not preloaded?')
          }

          const id = getters.item[propName]
          // Test if id value is null...
          if (null === id || undefined === id) {
            commit(SET_SELECTED_ITEM + propName, null)
            resolve()
            return
          }
          // ... or if selected value is already correct...
          const selected = getters[propName + '_selected']
          if (selected && selected['id'] === id) {
              resolve()
              return
          }
          // ... or if it is in list of options.
          if (getters[propName + '_options'].some((item) => {
            if (item['id'] === id) {
              commit(SET_SELECTED_ITEM + propName, item)
              return true
            }
          })) {
            resolve()
            return
          }
          // Extract the id from the IRI.
          const uri = listOperation.url + '?id=' + id.split('/').pop()
          dispatch('api/fetch', {uri}, { root: true })
            .then(response => response.json())
            .then((data) => {
              const options = data['hydra:member']
              commit(SET_SELECTED_ITEM + propName, options[0])
              resolve()
            })
            .catch((e) => {
              reject(e)
            })
        }))
      })
      return Promise.all(promises)
    }
    result.actions.resetSelections = function ({dispatch, commit}) {
      const promises = []
      referenceNames.map((propName) => {
        commit(SET_SELECTED_ITEM + propName, null)
        if (properties[propName].type === 'array') {
          promises.push(dispatch('fetch_' + propName, ''))
          return
        }
        promises.push(dispatch('search_' + propName, ''))
      })
      return Promise.all(promises)
    }

    referenceNames.map((propName) => {
      const listOperation = references[propName].operations.list
      result.state = {
        ...result.state,
        [propName + '_options']: [],
        [propName + '_selected']: null,
      }

      result.getters = {
        ...result.getters,
        [propName + '_options']: state => state[propName + '_options'],
        [propName + '_selected']: state => state[propName + '_selected'],
      }

      result.mutations = {
        ...result.mutations,
        [SET_SELECTED_ITEM + propName] (state, selectedItem) {
          Vue.set(state, propName + '_selected', selectedItem)
        },
        [SET_OPTIONS + propName] (state, options) {
          Vue.set(state, propName + '_options', options)
        }
      }

      // If single item, we support searching.
      if (properties[propName].type !== 'array') {
        result.state[propName + '_search'] = ''
        result.getters = {
          ...result.getters,
          [propName + '_search']: state => state[propName + '_search'],
        }
        result.mutations = {
          ...result.mutations,
          [UPDATE_SEARCH + propName] (state, search) {
            Vue.set(state, propName + '_search', search)
          },
        }
        result.actions = {
          ...result.actions,
          // Selected element in list.
          ['search_' + propName] ({ commit, dispatch }, search) {
            commit(UPDATE_SEARCH + propName, search)
            const params = [
              'itemsPerPage=10'
            ]
            if (search) {
              params.push('&orSearch_contains=' + search)
            }
            const uri = listOperation.url + '?' + params.join('&')
            return new Promise((resolve, reject) => {
              dispatch('api/fetch', {uri}, { root: true })
                .then(response => response.json())
                .then((data) => {
                  commit(SET_OPTIONS + propName, data['hydra:member'])
                  resolve()
                })
                .catch((e) => {
                  reject(e)
                })
            })
          },
        }
      } else {
        // Collections are preloaded via fetch_ action.
        result.actions = {
          ...result.actions,
          // Selected element in list.
          ['fetch_' + propName] ({ commit, dispatch }) {
            const params = [
              'itemsPerPage=999'
            ];
            const uri = listOperation.url + '?' + params.join('&')
            return new Promise((resolve, reject) => {
              dispatch('api/fetch', {uri}, { root: true })
                .then(response => response.json())
                .then((data) => {
                  commit(SET_OPTIONS + propName, data['hydra:member'])
                  resolve()
                })
                .catch((e) => {
                  reject(e)
                })
            })
          },
        }
      }
    })
  }

  return result
}
