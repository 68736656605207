import Vue from 'vue'
import Vuex from 'vuex';
import parseSwagger from './docs-parser/swagger-parser.js'
import merge from '../util/merge-object.js'

import toasts from './misc/toasts.js'
import api from './module/api.js'
import authentication from './module/authentication.js'
import create from './module/create.js'
import list from './module/list.js'
import update from './module/update.js'
import delete_ from './module/delete.js'

Vue.use(Vuex);

/**
 * @typedef ModuleBuilderContext
 *
 * @property {object} resource
 * @property {object} parsed
 * @property {string} $name
 * @property {string} $namespace
 * @property {string} $parentNamespace
 */

/**
 * Build the store modules.
 *
 * @param {object} apiDefinition The parsed API information.
 * @return {Promise<any>}
 */
function buildModules (apiDefinition) {

  function createSubmodule (resource) {
    const modules = {};
    if (resource.operations.create) {
      modules.create = create(resource, apiDefinition);
    }
    if (resource.operations.update) {
      modules.update = update(resource, apiDefinition)
    }
    if (resource.operations.delete) {
      modules.del = delete_(resource, apiDefinition);
    }
    if (resource.operations.list) {
      modules.list = list(resource, apiDefinition);
    }

    // if (resource.operations.show) {
    //   modules.show = moduleShow(subContext(context, 'show'));
    // }

    return {
      namespaced: true,
      modules
    }
  }

  return new Promise((resolve) => {
    const result = {
      api: api(apiDefinition.entrypoint),
      authentication: authentication(apiDefinition),
      toasts,
    };
    for (let [name, resource] of Object.entries(apiDefinition.resource)) {
      if (name === 'authenticate') {
        continue;
      }
      result[name] = createSubmodule(resource);
    }
    resolve(result)
  })
}

function addOverrides (apiDefinition, overrides) {
  return new Promise((resolve => {
    merge(apiDefinition, overrides)
    resolve(apiDefinition)
  }))
}

function build (entrypoint, overrides = {}, fixup = (storeConfig) => storeConfig) {
  return new Promise((resolve) => {
    const apiDefinition = {
      entrypoint
    }
    Promise.all([
      parseSwagger(apiDefinition),
      // FIXME: add hydra parser here.
      addOverrides(apiDefinition, overrides)
    ]).then(() => {
      buildModules(apiDefinition)
        .then((modules) => {
          resolve(new Vuex.Store(fixup({
            /* global process */
            strict: process.env.NODE_ENV !== 'production',
            state: {
              runningRequestCount: 0,
              apiDefinition,
            },
            modules,
            actions: {
              startRequest ({commit}) {
                commit('startRequest');
              },
              endRequest ({commit}) {
                commit('endRequest');
              },
            },
            getters: {
              isLoading: (state) => {
                return state.runningRequestCount > 0;
              },
              apiDefinition: state => state.apiDefinition,
            },
            mutations: {
              startRequest (state) {
                state.runningRequestCount++;
              },
              endRequest (state) {
                state.runningRequestCount--;
              },
            }
          })))
        })
    })
  })
}

export default build
