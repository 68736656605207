<script>
  import {
    BAlert,
    BRow,
  } from 'bootstrap-vue'

  import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
  import EditHeader from './EditHeader.vue'

  export default {
    components: {
      BAlert,
      BRow,
      EditHeader
    },
    props: {
      store: {
        type: String,
        required: true
      },
      translationRoot: {
        type: String,
        default: undefined
      },
      updateRoute: {
        type: String,
        required: true
      },
      init: {
        type: Function,
        default: undefined
      }
    },
    data () {
      return {
        iconError: faExclamationTriangle
      }
    },
    computed: {
      isLoading () {
        return this.$store.getters.isLoading
      },
      error () {
        return this.$store.getters[this.store + '/create/error']
      },
      violations () {
        return this.$store.getters[this.store + '/create/violations']
      },
      translations () {
        return this.translationRoot ? this.translationRoot : this.store.split('/')[0]
      },
    },
    beforeDestroy () {
        this.clear()
    },
    mounted () {
      this.clear().then(() => {
        if (this.init) {
          this.init()
        }
      })
    },
    methods: {
      clear () {
        return this.$store.dispatch(this.store + '/create/clear')
      },
      onSendForm () {
        this.$store.dispatch(this.store + '/create/save').then(
          (item) => {
            this.$store.dispatch('toasts/addToast', {
              variant: 'success',
              title: {message: `${this.translations}.toasts.create-success.title`, parameters: {...item}},
              content: {message: `${this.translations}.toasts.create-success.content`, parameters: {...item}},
              hideAfter: 5,
            })
            this.$router.replace({ name: this.updateRoute, params: { id: item['id'] } })
          },
          (error) => {
            this.$store.dispatch('toasts/addToast', {
              variant: 'danger',
              title: {message: `${this.translations}.toasts.create-fail.title`, parameters: {error, ...this.$store.getters[this.store + '/create/item']}},
              content: {message: `${this.translations}.toasts.create-fail.content`, parameters: {error, ...this.$store.getters[this.store + '/create/item']}},
            })
          }
        )
      }
    }
  }
</script>

<template>
  <form
    class="container-fluid"
    :disabled="isLoading"
    @submit.prevent="onSendForm"
  >
    <edit-header
      :store="store"
      :show-delete="false"
    />
    <b-row>
      <b-alert
        v-if="error"
        show
        variant="danger"
        class="col-12"
      >
        <font-awesome-icon :icon="iconError" /> {{ error }}
      </b-alert>
    </b-row>
    <b-row>
      <slot :store="store + '/create'" />
    </b-row>
  </form>
</template>
