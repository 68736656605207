<script>
  import LookupSelect from '../input/LookupSelect.vue'
  import formFieldMixin from './form-field-mixin.js'

  import {
    BFormGroup,
  } from 'bootstrap-vue'

  export default {
    components: {
      BFormGroup,
      LookupSelect
    },
    mixins: [
      formFieldMixin
    ],
    props: {
      optionKey: {
        type: String,
        default: '@id'
      },
      clearButton: {
        type: Boolean,
        default: true
      },
      editButton: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      searchPlaceholder () {
        return this.$t(`${this.translations}.${this.propName}.searchPlaceholder`)
      },

      options () {
        return this.$store.getters[`${this.store}/${this.propName}_options`]
      },
      selectedItem () {
        return this.$store.getters[`${this.store}/${this.propName}_selected`]
      },
      searchValue () {
        return this.$store.getters[`${this.store}/${this.propName}_search`]
      },
    },
    methods: {
      select (value) {
        this.$store.dispatch(`${this.store}/updateItem`, {[this.propName]: value})
      },
      search (search) {
        this.$store.dispatch(`${this.store}/search_${this.propName}`, search || null)
      },
      edit (selected) {
        this.$emit('edit', selected)
      }
    }
  }
</script>
<template>
  <b-form-group
    :description="description"
    :label="label"
    :label-for="inputName"
    :invalid-feedback="violations"
    :state="!isInvalid"
    :label-class="labelClasses"
  >
    <lookup-select
      :id="inputName"
      :placeholder="placeholder"
      :search-placeholder="searchPlaceholder"
      :icon="true"
      :option-key="optionKey"
      :clear-button="clearButton"
      :edit-button="editButton"
      :state="!isInvalid"
      :disabled="isReadOnly"
      :options="options"
      :selected="value"
      :selected-item="selectedItem"
      :search-value="searchValue"
      @select="select"
      @search="search"
      @edit-ref="edit"
    >
      <template #selected-item="slotProps">
        <slot
          name="selected-item"
          :item="slotProps.item"
        >
          {{ slotProps.item }}
        </slot>
      </template>
      <template #item="slotProps">
        <slot
          name="item"
          :item="slotProps.item"
        >
          {{ slotProps.item }}
        </slot>
      </template>
    </lookup-select>
  </b-form-group>
</template>
