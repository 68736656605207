<script>

import {BButton} from 'bootstrap-vue'
import {faEdit} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    BButton,
  },
  props: {
    repository: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      iconEdit: faEdit,
    }
  }
}
</script>
<template>
  <b-button
    v-if="!!repository.id"
    :to="{name: 'RepositoryUpdate', params: {id: repository.id}}"
    :title="$t('button.edit')"
    variant="link"
  >
    {{ repository.name }}
    <font-awesome-icon :icon="iconEdit" />
  </b-button>
</template>
