
const filterInt = function (value) {
  if(/^[-+]?([0-9]+|Infinity)$/.test(value))
    return Number(value);
  return NaN;
}

/**
 * Build a filter handler.
 *
 * @param {object} operation
 * @param {object} operation.parameters
 * @param {array} operation.filter
 * @param {string} operation.url
 * @param {string} operation.url_absolute
 */
export default function FilterUrl (operation) {
  if (!operation.filter) {
    throw new Error('Missing filters.')
  }
  if (!operation.url_absolute) {
    throw new Error('Missing url_absolute.')
  }
  const filters = {}
  const sortings = {}
  operation.filter.map((parameter) => {
    if (!parameter.name) {
      throw new Error(`Parameter has no name.`)
    }
    const sortMatch = parameter.name.match(/order\[(.*)\]/)
    if (sortMatch && sortMatch.length === 2) {
      sortings[sortMatch[1]] = parameter
      return
    }
    if (!parameter.type) {
      throw new Error(`Parameter has no type: ${parameter.name}`)
    }
    // FIXME: should create a copy?
    filters[parameter.name] = parameter
  })

  this.allowedSort = () => sortings
  this.isAllowedSort = (name) => !!sortings[name]

  this.has = (name) => !!filters[name]

  this.defaultFilter = (undefinedOverride) => {
    undefinedOverride = undefinedOverride || {}
    const result = {}
    for (let [parameterName, filter] of Object.entries(filters)) {
      if (Object.prototype.hasOwnProperty.call(filter, 'default')) {
        result[parameterName] = filter.default
      } else if (Object.prototype.hasOwnProperty.call(undefinedOverride, parameterName)) {
        result[parameterName] = undefinedOverride[parameterName]
      } else {
        result[parameterName] = undefined
      }
    }
    return result
  }

  this.buildUrl = (values = {}, sort = {}, parameters = {}) => {
    let url_absolute = operation.url_absolute
    if (operation.parameter && operation.parameter.length > 0) {
      for (let i = 0; i < operation.parameter.length; i++) {
        const parameter = operation.parameter[i]
        const name = parameter.name
        const value = parameters[name]
        if (value === undefined || value === null) {
          if (parameter.required) {
            throw new Error(`Required URL parameter "${name}" is empty.`)
          }
          continue
        }
        // FIXME: need to convert the URL parameters to string here.
        url_absolute = url_absolute.replace('{id}', value)
      }
    }
    const url = new URL(url_absolute, url_absolute)
    // Append filters now.
    for (let [name, filter] of Object.entries(filters)) {
      if (Object.prototype.hasOwnProperty.call(values, name)) {
        const value = values[name];
        if (value === undefined) {
          if (filter.required) {
            throw new Error(`Required filter parameter "${name}" is empty.`)
          }
          continue
        }
        // Skip default values.
        if (Object.prototype.hasOwnProperty.call(filter, 'default') && value === filter.default) {
          continue
        }

        if (value === null) {
          url.searchParams.append(filter.name, null)
          continue
        }

        switch (filter.type) {
          case 'array':
            switch (filter.collectionFormat) {
              case 'multi':
                // FIXME: should encode the value as 'filter.items.type'
                value.map((value) => {
                  url.searchParams.append(filter.name, value)
                })
                break;
              case 'csv':
                url.searchParams.append(filter.name, value.join(','))
                break;
              case 'ssv':
                url.searchParams.append(filter.name, value.join(' '))
                break;
              case 'tsv':
                url.searchParams.append(filter.name, value.join('\t'))
                break;
              case 'pipes':
                url.searchParams.append(filter.name, value.join('|'))
                break;
              default:
                throw new Error(`Unknown parameter array type: ${filter.collectionFormat}`)
            }
            break;
          case 'string':
            // Ensure we have a proper uuid and no IRI.
            // FIXME: our private hack for references... :(
            if (filter.format && filter.format.substring(0, 14) === 'iri-reference<') {
              url.searchParams.append(filter.name, value.split('/').pop())
              break;
            }
            url.searchParams.append(filter.name, value)
            break;
          case 'integer':
            if (!isNaN(value)) {
              url.searchParams.append(filter.name, value)
            }
            break;
          case 'boolean':
            url.searchParams.append(filter.name, value ? 'true' : 'false')
            break;
          default:
            throw new Error(`Unknown parameter type: ${name} ${filter.type}`)
        }
        // TODO:
      }
    }
    for (let [name, direction] of Object.entries(sort)) {
      if (Object.prototype.hasOwnProperty.call(sortings, name)) {
        url.searchParams.append(`order[${name}]`, direction)
      }
    }

    return url
  }

  this.parseUrl = (url) => {
    const currentUrl = new URL(url, operation.url_absolute)
    const parameters = currentUrl.searchParams

    const parsed = {}
    for (let [name, filter] of Object.entries(filters)) {
      if (!parameters.has(name)) {
        parsed[name] = Object.prototype.hasOwnProperty.call(filter, 'default') ? filter.default : undefined;
        continue;
      }
      const value = parameters.get(name)

      if (value === 'null') {
        parsed[name] = null
        continue
      }

      switch (filter.type) {
        case 'array':
          switch (filter.collectionFormat) {
            case 'multi':
              parsed[name] = parameters.getAll(name)
              break;
            case 'csv':
              parsed[name] = value.split(',')
              break;
            case 'ssv':
              parsed[name] = value.split(' ')
              break;
            case 'tsv':
              parsed[name] = value.split('\t')
              break;
            case 'pipes':
              parsed[name] = value.split('|')
              break;
            default:
              console.error('unknown parameter array type', name, filter, value)
          }
          break;
        case 'string':
          parsed[name] = value
          break;
        case 'integer':
          parsed[name] = filterInt(value)
          break;
        case 'boolean':
          parsed[name] = value === 'true'
          break;
        default:
          console.error('unknown parameter type', name, filter, value)
      }
    }

    return parsed
  }
}
