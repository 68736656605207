<script>

  import {
    BCol,
  } from 'bootstrap-vue'

  import ListView from '../partials/ListView.vue'
  import ListSortToggler from '../partials/ListSortToggler.vue'

  export default {
    components: {
      BCol,
      ListView,
      ListSortToggler,
    }
  }
</script>
<template>
  <list-view
    store="user"
    add-route="UserCreate"
    edit-route="UserUpdate"
  >
    <template #header>
      <b-col md="4">
        <list-sort-toggler
          store="user"
          prop-name="name"
        >
          {{ $t('user.name.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col md="2">
        <list-sort-toggler
          store="user"
          prop-name="username"
        >
          {{ $t('user.username.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col md="4">
        <list-sort-toggler
          store="user"
          prop-name="email"
        >
          {{ $t('user.email.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template #item="slotProps">
      <b-col md="4">
        <div class="p-2">
          {{ slotProps.item.name }}
        </div>
      </b-col>
      <b-col md="2">
        <div class="p-2">
          {{ slotProps.item.username }}
        </div>
      </b-col>
      <b-col md="4">
        <div class="p-2">
          {{ slotProps.item.email }}
        </div>
      </b-col>
    </template>
  </list-view>
</template>

