import Vue from 'vue'
import VueI18n from 'vue-i18n/dist/vue-i18n.esm.js'

Vue.use(VueI18n)

// LOCALES is set by webpack to all files within './i18n' directory.
/* global LOCALES */
export const locales = LOCALES
export const defaultLocale = (navigator.language || navigator.userLanguage).split('-')[0]

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: require('../static/i18n/en.json'),
  },
  dateTimeFormats: {
    en: require('../static/i18n/datetime-en.json'),
  },
})

const loadedLanguages = ['en'] // our default language that is preloaded

function setI18nLanguage (lang) {
  return lang
}

function loadLanguageFile(file) {
  return fetch(`/dist/i18n/${file}.json`)
    .then(response => response.json())
}

export function loadLanguageAsync (lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return new Promise(resolve => {
        Promise.all([
          loadLanguageFile(`${lang}`).then(msgs => {
            i18n.setLocaleMessage(lang, msgs)
          }),
          loadLanguageFile(`datetime-${lang}`).then(format => {
            i18n.setDateTimeFormat(lang, format)
          })
        ]).then(() => {
          loadedLanguages.push(lang)
          resolve(setI18nLanguage(lang))
        })

      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}
