const MIME_TYPE = 'application/json'

export default function fetchDocs (url, options = {}) {
  if (typeof options.headers === 'undefined') {
    Object.assign(options, { headers: new window.Headers() })
  }

  if (options.headers.get('Accept') === null) {
    options.headers.set('Accept', MIME_TYPE)
  }

  if (options.body !== undefined && !(options.body instanceof window.FormData) && options.headers.get('Content-Type') === null) {
    options.headers.set('Content-Type', MIME_TYPE)
  }

  return window.fetch(url, options).then((response) => {
    if (response.ok) {
      return response
    }
    response.text().then((text) => {
      throw Error(text)
    })
  })
}
