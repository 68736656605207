export default {
  computed: {
    translations () {
      return this.translationRoot ? this.translationRoot : this.store.split('/')[0]
    },
    description () {
      return this.$t(`${this.translations}.${this.propName}.description`)
    },
    label () {
      return this.$t(`${this.translations}.${this.propName}.label`)
    },
    placeholder () {
      return this.$t(`${this.translations}.${this.propName}.placeholder`)
    },
    labelClasses () {
      return this.hideLabel ? 'sr-only' : ''
    }
  },
  props: {
    store: {
      type: String,
      required: true
    },
    translationRoot: {
      type: String
    },
    propName: {
      type: String,
      required: true
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  }
}
