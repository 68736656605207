import FilterUrl from '../filter-url.js'
import merge from '../../util/merge-object.js'
import {itemEmpty} from './common.js'

import Vue from 'vue'

export default function list(resource, apiDefinition) {
  if (!resource.name) {
    throw new Error('Missing name.')
  }
  const operation = resource.operations.list
  if (!operation) {
    throw new Error('Missing list operation.')
  }
  if (!operation.url_absolute) {
    throw new Error('Missing url_absolute.')
  }

  const filters = new FilterUrl(operation)

  const uc = resource.name.toUpperCase();
  const RESET = uc + '_LIST_RESET';
  const SET_ITEMS = uc + '_LIST_SET_ITEMS';
  const SET_ERROR = uc + '_LIST_SET_ERROR';
  const SET_VIEW = uc + '_LIST_SET_VIEW';
  const UPDATE_FILTER = uc + '_LIST_UPDATE_FILTER';
  const SET_REFERENCED_ITEMS = uc + '_SET_REFERENCED_ITEMS';
  const TOGGLE_SORT = uc + '_LIST_TOGGLE_SORT';
  const SET_PARAMETERS = uc + '_SET_PARAMETERS';

  const result = {
    namespaced: true,
    state: {
      error: '',
      items: [],
      view: {},
      filter: filters.defaultFilter({
        totalItems: NaN,
        itemsPerPage: 20,
        page: 1,
      }),
      allowedSort: filters.allowedSort(),
      sortBy: {
      },
      parameters: {}
    },
    actions: {
      default: ({ commit, dispatch, state}, {page = undefined, parameters = undefined} = {}) => {
        if (page === undefined) {
          // Initialize with base URL and reuse parameters.
          if (parameters) {
            commit(SET_PARAMETERS, parameters)
          }
          page = filters.buildUrl(state.filter, state.sortBy, state.parameters)
        }
        return new Promise((resolve, reject) => {
          dispatch('api/fetch', {uri: page}, { root: true })
            .then(response => response.json())
            .then((data) => {
              commit(SET_ERROR, '')
              commit(SET_ITEMS, data['hydra:member'])
              commit(SET_VIEW, data['hydra:view'])

              const filter = filters.parseUrl(data['hydra:view'] ? data['hydra:view']['@id'] : page)
              filter.totalItems = parseInt(data['hydra:totalItems']) || NaN
              // FIXME: can we implement this somehow better?
              if (isNaN(filter.itemsPerPage)) {
                filter.itemsPerPage = 20
              }
              // This happens when there are less than one page of items.
              if (isNaN(filter.page)) {
                filter.page = 1
              }

              commit(UPDATE_FILTER, filter)
              dispatch('loadReferences', data['hydra:member'])
              resolve(data)
            })
            .catch((e) => {
              commit(SET_ERROR, e.message)
              reject(e)
            })
        })
      },
      reset: ({commit, dispatch}) => {
        commit(RESET)
        dispatch('resetReferences')
      },
      setFilter: ({ commit, dispatch }, payload) => {
        commit(UPDATE_FILTER, payload)
        dispatch('default')
      },
      toggleSort: ({ commit, dispatch }, name) => {
        commit(TOGGLE_SORT, name)
        dispatch('default')
      },
      loadReferences () {
        return Promise.resolve()
      },
      resetReferences () {
        return Promise.resolve()
      }
    },
    getters: {
      error: state => state.error,
      items: state => state.items,
      view: state => state.view,
      filter: state => state.filter,
      hasFilter: () => (name) => filters.has(name),
      isAllowedSort: () => (name) => filters.isAllowedSort(name),
      getSort: state => (name) => state.sortBy[name]
    },
    mutations: {
      [RESET] (state) {
        Object.assign(state, {
          error: '',
          isLoading: false,
          items: [],
          view: {},
          filter: filters.defaultFilter({
            totalItems: NaN,
            itemsPerPage: 20,
            page: 1,
          })
        })
      },
      [SET_ERROR] (state, error) {
        Object.assign(state, { error })
      },
      [SET_ITEMS] (state, items) {
        Object.assign(state, { items })
      },
      [SET_VIEW] (state, view) {
        Object.assign(state, { view })
      },
      [UPDATE_FILTER] (state, filter) {
        state.filter = merge(Object.assign({}, state.filter), filter)
      },
      [TOGGLE_SORT] (state, sortName) {
        const current = state.sortBy[sortName]
        switch (current) {
          case undefined:
            Vue.set(state.sortBy, sortName, 'ASC')
            break;
          case 'ASC':
            Vue.set(state.sortBy, sortName, 'DESC')
            break;
          case 'DESC':
            Vue.delete(state.sortBy, sortName)
        }
      },
      [SET_PARAMETERS] (state, parameters) {
        state.parameters = parameters
      }
    }
  }

  const properties = operation.output.items.properties
  const references = {}
  const referenceNames = []
  Object.keys(properties).map((propName) => {
    const property = properties[propName]
    const reference = (property.type === 'array') ? property.items.reference : property.reference
    if (reference) {
      const resource = apiDefinition.resource[reference]
      if (resource && resource.operations.list && resource.operations.list.filter.some((parameter) => parameter.name === 'id[]')) {
        references[propName] = resource
        referenceNames.push(propName)
      }
    }
  });

  if (referenceNames.length === 0) {
    return result
  }

  result.actions = {
    ...result.actions,
    loadReferences ({commit, dispatch}, items) {
      if (!items.length) {
        return dispatch('resetReferences')
      }
      return Promise.all(referenceNames.map((propName) => {
        // Create an uuid filter list.
        const ids = []
        const convertIds = (value) => {
          if (null === value || undefined === value) {
            return;
          }
          if (Array.isArray(value)) {
            value.map(convertIds)
            return
          }
          const id = value.split('/').pop()
          if (-1 === ids.indexOf(id)) {
            ids.push(id)
          }
        }
        items.map((item) => convertIds(item[propName]));

        if (!ids.length) {
          commit(SET_REFERENCED_ITEMS, {['ref_' + propName]: []})
          return Promise.resolve();
        }

        const uri = references[propName].operations.list.url + '?'
          + ids.map((value) => 'id[]=' + value).join('&')
          + '&itemsPerPage=' + ids.length
        return dispatch('api/fetch', {uri}, { root: true })
          .then(response => response.json())
          .then((data) => {
            // Resort items.
            const items = {}
            data['hydra:member'].map((item) => {
              items[item.id] = item
            })
            commit(SET_REFERENCED_ITEMS, {['ref_' + propName]: items})
          })
          .catch((e) => {
            commit(SET_ERROR, e.message)
          })
      }))
    },
    resetReferences ({commit}) {
      return Promise.all(referenceNames.map((propName) => {
        commit(SET_REFERENCED_ITEMS, {['ref_' + propName]: {}})
      }))
    }
  }
  result.mutations = {
    ...result.mutations,
    [SET_REFERENCED_ITEMS] (state, items) {
      Object.assign(state, items)
    },
  }

  const refGetters = {}
  const refState = {}
  referenceNames.map((propName) => {
    refGetters['ref_' + propName] = state => (id) => {
      // Could be a hydra id value.
      if (id && id.match(/\//)) {
        id = id.split('/').pop()
      }
      if (id && state['ref_' + propName][id]) {
        return state['ref_' + propName][id]
      }

      return itemEmpty(resource.operations.list.output.items.properties)
    }
    refState['ref_' + propName] = {}
  })
  result.getters = {
    ...result.getters,
    ...refGetters
  }
  result.state = {
    ...result.state,
    ...refState
  }

  return result
}
