<script>
  import EditView from '../partials/EditView.vue'
  import FormInput from '../form/form-input.vue'
  import FormRepositoryAuth from '../form/form-repository-auth.vue'
  import tokenMixin from '../form/token-mixin.js'

  import {
    BButton,
    BInputGroupAppend,
  } from 'bootstrap-vue'

  export default {
    components: {
      BButton,
      BInputGroupAppend,
      EditView,
      FormInput,
      FormRepositoryAuth,
    },
    mixins: [
      tokenMixin
    ],
    props: {
      id: {
        type: String,
        required: true
      }
    },
  }
</script>
<template>
  <edit-view
    :id="id"
    v-slot="slotProps"
    store="packagedescriptorauth"
    list-route="PackageDescriptorList"
  >
    <form-input
      :store="slotProps.store"
      prop-name="name"
      class="col-6"
    />
    <form-repository-auth
      :store="slotProps.store"
      prop-name="auth"
      class="col-12"
    />
    <form-input
      :store="slotProps.store"
      prop-name="webHookToken"
      class="col-6"
    >
      <b-input-group-append>
        <b-button
          :title="$t('packagedescriptorauth.webHookToken.generateTitle')"
          @click="$store.dispatch('packagedescriptorauth/update/updateItem', {webHookToken: random()})"
        >
          <font-awesome-icon :icon="iconRandom" />
        </b-button>
      </b-input-group-append>
    </form-input>
  </edit-view>
</template>
