import {handleApiError, itemPayload, apiUrl} from './common.js'
import editItem from './edit-item.js'

export default function buildModuleUpdate(resource, apiDefinition) {
  const SET_ERROR = 'SET_ERROR'

  const getOperation = resource.operations.show
  const updateOperation = resource.operations.update
  const properties = updateOperation.input.properties

  return editItem({
    state: {
      error: '',
    },
    actions: {
      load: ({commit, dispatch}, id) => {
        return new Promise((resolve, reject) => {
          dispatch('reset').then(() => {
            dispatch('api/fetch', {uri: apiUrl(getOperation, {id})}, {root: true})
              .then(response => response.json())
              .then((data) => {
                dispatch('setItem', data)
                  .then((data) => {
                    commit(SET_ERROR, '')
                    resolve(data)
                  })
              })
              .catch((e) => {
                commit(SET_ERROR, e.message)
                reject(e)
              })
          })
        })
      },
      save: ({ commit, dispatch, getters }) => {
        commit(SET_ERROR, '')

        return new Promise((resolve, reject) => {
          dispatch('api/put', {uri: apiUrl(updateOperation, getters.item), options: {
              body: JSON.stringify(itemPayload(properties, getters.item))
            }}, { root: true })
            .then(response => response.json())
            .then((data) => {
              commit(SET_ERROR, '')
              Promise.all([
                dispatch('setViolations', null),
                dispatch('setItem', data)
              ]).then(() => {
                resolve(data)
              })
            })
            .catch((error) => {
              handleApiError({commit, dispatch}, error)
              reject(error)
            })
        })
      },
    },
    getters: {
      error: state => state.error,
    },
    mutations: {
      [SET_ERROR] (state, error) {
        Object.assign(state, { error })
      },
    }
  }, properties, apiDefinition)
}
