<script>
  import {
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BRow,
  } from 'bootstrap-vue'

  import hotKeys from '../../hotkeys.js'

  import {faBroom} from '@fortawesome/free-solid-svg-icons'
  import {faPlusSquare} from '@fortawesome/free-regular-svg-icons'

  /**
   * Requires:
   *
   * @property {object} props
   * @property {string} props.storeNamespace
   */
  export default {
    components: {
      BButton,
      BInputGroup,
      BInputGroupAppend,
      BInputGroupPrepend,
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BPagination,
      BRow,
    },
    props: {
      storeNamespace: {type: String, required: true},
      addTo: {type: [Object, null], default: null}
    },
    data: () => {
      return {
        iconAdd: faPlusSquare,
        iconClear: faBroom,
        orSearch_timeout: null,
        hotKeys,
      }
    },
    computed: {
      showSearchFilter () {
        return this.$store.getters[this.$props.storeNamespace + '/list/hasFilter']('orSearch_contains')
      },
      filter () {
        return this.$store.getters[this.$props.storeNamespace + '/list/filter']
      },
      filterString () {
        return this.filter.orSearch_contains
      },
      itemsPerPage () {
        return this.filter.itemsPerPage
      },
      totalItems () {
        return this.filter.totalItems
      },
      activePage () {
        return this.filter.page
      },
      showAddButton () {
        return this.addTo !== null
      }
    },
    methods: {
      handleFilterChange (orSearch_contains) {
        if(this.orSearch_timeout) {
          clearTimeout(this.orSearch_timeout)
        }
        this.orSearch_timeout = setTimeout(() => {
          this.orSearch_timeout = null
          this.$store.dispatch(this.$props.storeNamespace + '/list/setFilter', {orSearch_contains})
        }, 400);
      },
      clearFilter () {
        this.$store.dispatch(this.$props.storeNamespace + '/list/setFilter', {orSearch_contains: ''})
      },
      handlePerPageChange (itemsPerPage) {
        this.$store.dispatch(this.$props.storeNamespace + '/list/setFilter', {itemsPerPage})
      },
      handlePageChange (page) {
        this.$store.dispatch(this.$props.storeNamespace + '/list/setFilter', {page})
      },
      hotKeyAdd () {
        this.$router.push(this.addTo)
      }
    }
  }
</script>
<template>
  <b-row class="filter-header">
    <b-form
      inline
      class="col-12"
    >
      <b-form-group
        v-if="showSearchFilter"
        class="mb-2 mr-sm-2 mb-sm-0"
      >
        <b-input-group>
          <b-input-group-prepend
            tag="label"
            is-text
            for="filter"
          >
            {{ $t('filter-header.filter.label') }}
          </b-input-group-prepend>
          <b-form-input
            id="filter"
            type="text"
            :placeholder="$t('filter-header.filter.placeholder')"
            :value="filterString"
            @input="handleFilterChange"
          />
          <b-input-group-append>
            <b-button
              :title="$t('filter-header.filter.btn-clear')"
              @click="clearFilter"
            >
              <font-awesome-icon :icon="iconClear" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <slot />
      <b-form-group class="mb-2 mr-sm-2 mb-sm-0">
        <b-input-group>
          <b-input-group-prepend
            tag="label"
            is-text
            for="perPage"
          >
            {{ $t('filter-header.per-page.label') }}
          </b-input-group-prepend>
          <b-form-select
            id="perPage"
            :value="itemsPerPage"
            @change="handlePerPageChange"
          >
            <option :value="10">
              10
            </option>
            <option :value="20">
              20
            </option>
            <option :value="50">
              50
            </option>
            <option :value="100">
              100
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
      <b-form-group class="mb-2 mr-sm-2 mb-sm-0">
        <b-input-group>
          <b-pagination
            :value="activePage"
            :per-page="itemsPerPage"
            :total-rows="totalItems"
            right
            @change="handlePageChange"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group class="mb-2 mr-sm-2 mb-sm-0 float-right">
        <b-input-group>
          <b-button
            v-if="showAddButton"
            v-shortkey.once="hotKeys.add"
            variant="success"
            :to="addTo"
            :title="$t('filter-header.btn-add.title')"
            @shortkey.native="hotKeyAdd"
          >
            <font-awesome-icon
              :icon="iconAdd"
              size="lg"
            />
            <span class="sr-only">{{ $t('filter-header.btn-add.label') }}</span>
          </b-button>
        </b-input-group>
      </b-form-group>
    </b-form>
  </b-row>
</template>
