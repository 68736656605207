<script>
  import {
    BAlert,
    BRow,
  } from 'bootstrap-vue'

  import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
  import EditHeader from './EditHeader.vue'

  export default {
    components: {
      BAlert,
      BRow,
      EditHeader
    },
    props: {
      store: {
        type: String,
        required: true
      },
      id: {
        type: String,
        required: true
      },
      translationRoot: {
        type: String,
        default: undefined
      },
      listRoute: {
        type: String,
        required: true
      },
      init: {
        type: Function,
        default: undefined
      }
    },
    data () {
      return {
        iconError: faExclamationTriangle
      }
    },
    computed: {
      isLoading () {
        return this.$store.getters.isLoading
      },
      error () {
        return this.$store.getters[this.store + '/update/error']
      },
      violations () {
        return this.$store.getters[this.store + '/update/violations']
      },
      translations () {
        return this.translationRoot ? this.translationRoot : this.store.split('/')[0]
      },
      item () {
        return this.$store.getters[this.store + '/update/item']
      },
    },
    beforeDestroy () {
      this.reset()
    },
    mounted () {
      if (this.init) {
        this.init().then(() => {
          this.$store.dispatch(this.store + '/update/load', decodeURIComponent(this.id))
        })
        return
      }
      this.$store.dispatch(this.store + '/update/load', decodeURIComponent(this.id))
    },
    methods: {
      updateReset () {
      },
      reset () {
        this.$store.dispatch(this.store + '/update/reset')
      },
      onDelete () {
        this.$store.dispatch(this.store + '/del/del', this.item)
          .then(
            (item) => {
              this.$store.dispatch('toasts/addToast', {
                variant: 'success',
                title: {message: `${this.translations}.toasts.delete-success.title`, parameters: {...item}},
                content: {message: `${this.translations}.toasts.delete-success.content`, parameters: {...item}},
                hideAfter: 5,
              })
              this.$router.push({name: this.listRoute})
            },
            (error) => {
              this.$store.dispatch('toasts/addToast', {
                variant: 'danger',
                title: {message: `${this.translations}.toasts.delete-fail.title`, parameters: {error, ...this.item}},
                content: {message: `${this.translations}.toasts.delete-fail.content`, parameters: {error, ...this.item}},
              }, { root: true })
            }
          )
      },
      onSendForm () {
        this.$store.dispatch(this.store + '/update/save').then(
          (item) => {
            this.$store.dispatch('toasts/addToast', {
              variant: 'success',
              title: {message: `${this.translations}.toasts.update-success.title`, parameters: {...item}},
              content: {message: `${this.translations}.toasts.update-success.content`, parameters: {...item}},
              hideAfter: 5,
            })
          },
          (error) => {
            this.$store.dispatch('toasts/addToast', {
              variant: 'danger',
              title: {message: `${this.translations}.toasts.update-fail.title`, parameters: {error, ...this.item}},
              content: {message: `${this.translations}.toasts.update-fail.content`, parameters: {error, ...this.item}},
            }, { root: true })
          }
        )
      }
    }
  }
</script>
<template>
  <form
    class="container-fluid"
    :disabled="isLoading"
    @submit.prevent="onSendForm"
  >
    <edit-header
      :store="store"
      @delete="onDelete"
    />
    <b-row>
      <b-alert
        v-if="error"
        show
        variant="danger"
        class="col-12"
      >
        <font-awesome-icon :icon="iconError" /> {{ error }}
      </b-alert>
    </b-row>
    <b-row>
      <slot
        :store="store + '/update'"
        :translationRoot="translations"
        :isLoading="isLoading"
      />
    </b-row>
  </form>
</template>
