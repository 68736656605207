import Vue from 'vue'
import VueRouter from 'vue-router';

import DashBoard from './components/DashBoard.vue'
import LoginPage from './components/LoginPage.vue'
import BundleCreate from './components/bundle/BundleCreate.vue'
import BundleList from './components/bundle/BundleList.vue'
import BundleUpdate from './components/bundle/BundleUpdate.vue'
import BundleConstraintCreate from './components/bundle-constraint/BundleConstraintCreate.vue'
import BundleConstraintList from './components/bundle-constraint/BundleConstraintList.vue'
import BundleConstraintUpdate from './components/bundle-constraint/BundleConstraintUpdate.vue'
import NotificationList from './components/notifications/NotificationList.vue'
import PackageDescriptorCreate from './components/package-descriptor/PackageDescriptorCreate.vue'
import PackageDescriptorList from './components/package-descriptor/PackageDescriptorList.vue'
import PackageDescriptorUpdate from './components/package-descriptor/PackageDescriptorUpdate.vue'
import PackageDescriptorAuthCreate from './components/package-descriptor-auth/PackageDescriptorAuthCreate.vue'
import PackageDescriptorAuthList from './components/package-descriptor-auth/PackageDescriptorAuthList.vue'
import PackageDescriptorAuthUpdate from './components/package-descriptor-auth/PackageDescriptorAuthUpdate.vue'
import PackageVersionDescriptorList from './components/package-version-descriptor/PackageVersionDescriptorList.vue'
import RepositoryCreate from './components/repository/RepositoryCreate.vue'
import RepositoryList from './components/repository/RepositoryList.vue'
import RepositoryUpdate from './components/repository/RepositoryUpdate.vue'
import RepositoryConstraintCreate from './components/repository-constraint/RepositoryConstraintCreate.vue'
import RepositoryConstraintList from './components/repository-constraint/RepositoryConstraintList.vue'
import RepositoryConstraintUpdate from './components/repository-constraint/RepositoryConstraintUpdate.vue'
import RepositoryTokenCreate from './components/repository-token/RepositoryTokenCreate.vue'
import RepositoryTokenList from './components/repository-token/RepositoryTokenList.vue'
import RepositoryTokenUpdate from './components/repository-token/RepositoryTokenUpdate.vue'
import EditSettings from './components/EditSettings.vue'
import UserCreate from './components/user/UserCreate.vue'
import UserList from './components/user/UserList.vue'
import UserUpdate from './components/user/UserUpdate.vue'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { name: 'LoginPage', path: '/login', component: LoginPage },
        { name: 'DashBoard', path: '/', component: DashBoard },
        { name: 'EditSettings', path: '/settings', component: EditSettings },
        {
            // Bundle related routes.
            name: 'Bundle',
            path: '/package-bundles/',
            component: {template: '<router-view></router-view>'},
            redirect: { name: 'BundleList' },
            children: [
                ...[
                    { name: 'BundleList', path: '', component: BundleList, props: true },
                    { name: 'BundleCreate', path: 'create', component: BundleCreate, props: true },
                    { name: 'BundleUpdate', path: 'edit/:id', component: BundleUpdate, props: true },
                ],
                ...[
                    { name: 'BundleConstraintList', path: ':bid/constraints', component: BundleConstraintList, props: true },
                    { name: 'BundleConstraintCreate', path: ':bid/constraints/create', component: BundleConstraintCreate, props: true },
                    { name: 'BundleConstraintUpdate', path: ':bid/constraints/edit/:id', component: BundleConstraintUpdate, props: true },
                ],
            ]
        },
        {
          // Notification related routes.
          name: 'Notification',
          path: '/notifications/',
          component: {template: '<router-view></router-view>'},
          redirect: { name: 'NotificationList' },
          children: [
            ...[
              { name: 'NotificationList', path: '', component: NotificationList },
            ],
          ]
        },
        {
            // PackageDescriptor related routes.
            name: 'PackageDescriptor',
            path: '/package-descriptors/',
            component: {template: '<router-view></router-view>'},
            redirect: { name: 'PackageDescriptorList' },
            children: [
                ...[
                    { name: 'PackageDescriptorList', path: '', component: PackageDescriptorList, props: true },
                    { name: 'PackageDescriptorCreate', path: 'create', component: PackageDescriptorCreate, props: true },
                    { name: 'PackageDescriptorUpdate', path: 'edit/:id', component: PackageDescriptorUpdate, props: true },
                    { name: 'PackageVersionDescriptorList', path: 'versions/:id', component: PackageVersionDescriptorList, props: true },
                ],
            ]
        },
        {
            // PackageDescriptorAuth related routes.
            name: 'PackageDescriptorAuth',
            path: '/package-descriptor-auth/',
            component: {template: '<router-view></router-view>'},
            redirect: { name: 'PackageDescriptorAuthList' },
            children: [
                ...[
                    { name: 'PackageDescriptorAuthList', path: '', component: PackageDescriptorAuthList, props: true },
                    { name: 'PackageDescriptorAuthCreate', path: 'create', component: PackageDescriptorAuthCreate, props: true },
                    { name: 'PackageDescriptorAuthUpdate', path: 'edit/:id', component: PackageDescriptorAuthUpdate, props: true },
                ],
            ]
        },
        {
            // Repository related routes.
            name: 'Repository',
            path: '/repositories/',
            component: {template: '<router-view></router-view>'},
            redirect: { name: 'RepositoryList' },
            children: [
                ...[
                    { name: 'RepositoryList', path: '', component: RepositoryList, props: true },
                    { name: 'RepositoryCreate', path: 'create', component: RepositoryCreate, props: true },
                    { name: 'RepositoryUpdate', path: 'edit/:id', component: RepositoryUpdate, props: true },
                ],
                ...[
                    { name: 'RepositoryConstraintList', path: ':rid/constraints', component: RepositoryConstraintList, props: true },
                    { name: 'RepositoryConstraintCreate', path: ':rid/constraints/create', component: RepositoryConstraintCreate, props: true },
                    { name: 'RepositoryConstraintUpdate', path: ':rid/constraints/edit/:id', component: RepositoryConstraintUpdate, props: true },
                ],
                ...[
                    { name: 'RepositoryTokenList', path: ':rid/tokens', component: RepositoryTokenList, props: true },
                    { name: 'RepositoryTokenCreate', path: ':rid/tokens/create', component: RepositoryTokenCreate, props: true },
                    { name: 'RepositoryTokenUpdate', path: ':rid/tokens/edit/:id', component: RepositoryTokenUpdate, props: true },
                ],
            ]
        },
        {
            // User related routes.
            name: 'User',
            path: '/users/',
            component: {template: '<router-view></router-view>'},
            redirect: { name: 'UserList' },
            children: [
                ...[
                    { name: 'UserList', path: '', component: UserList, props: true },
                    { name: 'UserCreate', path: 'create', component: UserCreate, props: true },
                    { name: 'UserUpdate', path: 'edit/:id', component: UserUpdate, props: true },
                ],
            ]
        },
    ]
});

export default router
