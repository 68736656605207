<script>
  import {
    BRow,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  } from 'bootstrap-vue'

  export default {
    components: {
      BRow,
      BCard,
      BForm,
      BFormGroup,
      BFormInput,
      BButton,
    },
    data () {
      return {
        username: '',
        password: '',
        submitted: false
      }
    },
    computed: {
      isLoading () {
        return this.$store.getters['isLoading'];
      }
    },
    created () {
      // reset login status
      this.$store.dispatch('authentication/logout');
    },
    methods: {
      handleSubmit (evt) {
        evt.preventDefault();
        this.submitted = true;
        const {username, password} = this;
        const {dispatch} = this.$store;
        if (username && password) {
          dispatch('authentication/login', {username, password});
        }
      }
    }
  };
</script>

<template>
  <b-row class="justify-content-center">
    <b-card
      bg-variant="light"
      class="col-6"
    >
      <b-form @submit="handleSubmit">
        <b-form-group
          :label="$t('login.username.label')"
          label-for="username"
          :state="!submitted || !!username"
          invalid-feedback="Username is required"
        >
          <b-form-input
            id="username"
            v-model="username"
            type="text"
            autocomplete="username"
          />
        </b-form-group>
        <b-form-group
          :label="$t('login.password.label')"
          label-for="password"
          :state="!submitted || !!password"
          invalid-feedback="Password is required"
        >
          <b-form-input
            id="password"
            v-model="password"
            type="password"
            autocomplete="current-password"
          />
        </b-form-group>
        <b-form-group>
          <b-button
            type="submit"
            variant="primary"
            :disabled="isLoading"
          >
            Login
          </b-button>
        </b-form-group>
      </b-form>
    </b-card>
  </b-row>
</template>
