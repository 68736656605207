import Vue from 'vue'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from './App.vue'
import {i18n} from './i18n.js'
import router from './router.js'

import {formatMoney, formatNumber, formatText, timeAgo} from './util/formatter.js'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import storeBuilder from './store/storeBuilder.js'

Vue.use(require('vue-shortkey'))
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

export function dis(apiUrl, sentryUrl) {
  if ((sentryUrl)) {
    Sentry.init({
      dsn: sentryUrl,
      integrations: [new Integrations.Vue({Vue, attachProps: true})],
    });
  }
  // API_URL is set by webpack when running in dev mode.
  /* global API_URL */
  if (API_URL) {
    apiUrl = API_URL
  }
  storeBuilder(apiUrl, {
    resource: {
      bundleconstraint: {
        operations: {
          list: {
            output: {
              items: {
                properties: {
                  bundle: {reference: 'bundle'},
                  packageDescriptor: {reference: 'packagedescriptor'}
                }
              }
            }
          },
          create: {
            input: {
              properties: {
                bundle: {reference: 'bundle'},
                packageDescriptor: {reference: 'packagedescriptor'}
              }
            }
          },
          update: {
            input: {
              properties: {
                bundle: {reference: 'bundle'},
                packageDescriptor: {reference: 'packagedescriptor'}
              }
            }
          },
        }
      },
      repository: {
        operations: {
          list: {
            output: {
              items: {
                properties: {
                  bundles: {reference: 'bundle'},
                }
              }
            }
          },
          create: {
            input: {
              properties: {
                bundles: {reference: 'bundle'},
              }
            }
          },
          update: {
            input: {
              properties: {
                bundles: {reference: 'bundle'},
              }
            }
          },
        }
      },
      repositoryconstraint: {
        operations: {
          list: {
            output: {
              items: {
                properties: {
                  repository: {reference: 'repository'},
                  packageDescriptor: {reference: 'packagedescriptor'}
                }
              }
            }
          },
          create: {
            input: {
              properties: {
                repository: {reference: 'repository'},
                packageDescriptor: {reference: 'packagedescriptor'}
              }
            }
          },
          update: {
            input: {
              properties: {
                repository: {reference: 'repository'},
                packageDescriptor: {reference: 'packagedescriptor'}
              }
            }
          },
        }
      },
      packagedescriptor: {
        operations: {
          list: {
            output: {
              items: {
                properties: {
                  originRepositoryAuth: {reference: 'packagedescriptorauth'}
                }
              }
            },
            parameters: {

            }
          },
          create: {
            input: {
              properties: {
                originRepositoryAuth: {reference: 'packagedescriptorauth'}
              }
            }
          },
          update: {
            input: {
              properties: {
                originRepositoryAuth: {reference: 'packagedescriptorauth'}
              }
            }
          },
        }
      },
    }
  }, (config) => {
    // Initialize default sorting - sadly there is no config exported via swagger for this.
    config.modules.bundle.modules.list.state.sortBy = {name: "ASC"}
    config.modules.bundleconstraint.modules.list.state.sortBy = {'bundle.name': 'ASC', 'packageDescriptor.name': 'ASC'}
    config.modules.packagedescriptor.modules.list.state.sortBy = {name: "ASC"}
    config.modules.repository.modules.list.state.sortBy = {name: "ASC"}
    config.modules.repositorytoken.modules.list.state.sortBy = {name: "ASC"}
    config.modules.repositoryconstraint.modules.list.state.sortBy = {'repository.name': "ASC", 'packageDescriptor.name': "ASC"}
    config.modules.user.modules.list.state.sortBy = {name: "ASC"}
    return config
  }).then((store) => {
    Vue.filter('formatMoney', formatMoney)
    Vue.filter('formatNumber', formatNumber)
    Vue.filter('formatText', formatText)
    Vue.filter('timeAgo', timeAgo)

    new Vue({
      el: '#app',
      store,
      router,
      i18n,
      render: h => h(App)
    })
  })
}
