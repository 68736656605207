<script>
  import { mapGetters } from 'vuex'

  import {
    BButton,
    BCol,
  } from 'bootstrap-vue'

  import {faEdit} from '@fortawesome/free-solid-svg-icons'
  import ListView from '../partials/ListView.vue'
  import ListSortToggler from '../partials/ListSortToggler.vue'

  export default {
    components: {
      BButton,
      BCol,
      ListView,
      ListSortToggler,
    },
    props: {
      bid: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        iconEdit: faEdit,
      }
    },
    computed: {
      ...mapGetters({
        bundle: 'bundleconstraint/list/ref_bundle',
        packageDescriptor: 'bundleconstraint/list/ref_packageDescriptor',
      })
    },
    mounted () {
      this.$store.dispatch('bundleconstraint/list/setFilter', {bundle: this.bid})
    }
  }
</script>
<template>
  <list-view
    store="bundleconstraint"
    add-route="BundleConstraintCreate"
    edit-route="BundleConstraintUpdate"
  >
    <template #header>
      <b-col md="4">
        <list-sort-toggler
          store="bundleconstraint"
          prop-name="bundle.name"
        >
          {{ $t('bundleconstraint.bundle.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col md="4">
        <list-sort-toggler
          store="bundleconstraint"
          prop-name="packageDescriptor.name"
        >
          {{ $t('bundleconstraint.packageDescriptor.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col
        md="2"
        class="text-right"
      >
        <list-sort-toggler
          store="bundleconstraint"
          prop-name="constraintText"
        >
          {{ $t('bundleconstraint.constraintText.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template #item="slotProps">
      <b-col md="4">
        <div class="p-2">
          {{ bundle(slotProps.item.bundle).name }}
        </div>
      </b-col>
      <b-col md="4">
        <b-button
          v-if="!!packageDescriptor(slotProps.item.packageDescriptor).id"
          :to="{name: 'PackageDescriptorUpdate', params: {id: packageDescriptor(slotProps.item.packageDescriptor).id}}"
          :title="$t('button.edit')"
          variant="link"
        >
          {{ packageDescriptor(slotProps.item.packageDescriptor).name }}
          <font-awesome-icon :icon="iconEdit" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-right"
      >
        <div class="p-2">
          {{ slotProps.item.constraintText }}
        </div>
      </b-col>
    </template>
  </list-view>
</template>
