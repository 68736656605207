<script>
  import formFieldMixin from './form-field-mixin.js'

  import {
    BFormGroup,
    BFormInput,
    BInputGroup,
  } from 'bootstrap-vue'

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BInputGroup,
    },
    mixins: [
      formFieldMixin
    ],
    props: {
      type: {
        type: String,
        default: 'text'
      },
      step: {
        type: String,
        default: '0.1'
      }
    }
  }
</script>
<template>
  <b-form-group
    :description="description"
    :label="label"
    :label-for="inputName"
    :invalid-feedback="violations"
    :state="!isInvalid"
    :label-class="labelClasses"
  >
    <input
      type="hidden"
      :autocomplete="autocomplete"
      value=""
    >
    <b-input-group>
      <b-form-input
        :id="inputName"
        :name="inputName"
        :value="value"
        :placeholder="placeholder"
        :state="!isInvalid"
        :readonly="isReadOnly"
        :type="type"
        :step="type === 'number' ? step : undefined"
        trim
        :autocomplete="autocomplete"
        @input="updateField($event)"
      />
      <slot />
    </b-input-group>
  </b-form-group>
</template>
