/* n: Value, c: Nachkomma, d: Dezimaltrenner t: Tausendertrenner */
export function formatter (n, c, d, t) {
  c = isNaN(c = Math.abs(c)) ? 2 : c
  d = d === undefined ? ',' : d
  t = t === undefined ? '.' : t
  const s = n < 0 ? '-' : ''
  const i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)))
  let j
  j = (j = i.length) > 3 ? j % 3 : 0
  return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
}

export function formatMoney (value, showZero) {
  return formatNumber(value, {showZero, suffix: '€'})
}

export function formatNumber (value, configuration) {
  configuration = configuration || {}
  if (value || configuration.showZero) {
    return formatter(value, configuration.decimals !== undefined ? configuration.decimals : 2) + (configuration.suffix || '')
  }
}

export function formatText (value) {
  return value.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export function timeDifference(current, previous, minimal = 'seconds') {
  if (!previous) {
    return {type: 'never'}
  }
  if (!(previous instanceof Date)) {
    previous = new Date(previous)
  }
  const elapsed = current - previous;
  if (isNaN(elapsed)) {
    return {type: 'never'}
  }

  const divisors = {
    years: () => {
      let months = (current.getFullYear() - previous.getFullYear()) * 12;
      months -= previous.getMonth();
      months += current.getMonth();
      const years = months / 12
      return (years <= 0) ? 0 : years;
    },
    months: () => {
      let months = (current.getFullYear() - previous.getFullYear()) * 12;
      months -= previous.getMonth();
      months += current.getMonth();
      return (months <= 0) ? 0 : months;
    },
    days: () => elapsed / 86400000,
    hours: () => elapsed / 3600000,
    minutes: () => elapsed / 60000,
    seconds: () => elapsed / (1000),
  }

  for (let [name, divisor] of Object.entries(divisors)) {
    let div = Math.floor(divisor())
    if ((1 <= div) || (minimal === name)) {
      return {type: name, [name]: div}
    }
  }

  const years = Math.round(elapsed/divisors.years)
  return {type: 'years', years}
}

export function timeAgo (previous) {
  return timeDifference(new Date(), previous)
}
