<script>
  import {
    BButton,
    BCol,
    BRow,
  } from 'bootstrap-vue'

  import {faTrashAlt, faBackward, faSave} from '@fortawesome/free-solid-svg-icons'
  import ReallyClick from '../ReallyClick.vue'
  import hotKeys from '../../hotkeys.js'

  export default {
    components: {
      BButton,
      BCol,
      BRow,
      ReallyClick
    },
    props: {
      store: { type: String, required: true},
      showDelete: {type: Boolean, default: true},
      canDelete: {type: Boolean, default: true}
    },
    data () {
      return {
        iconSave: faSave,
        iconDelete: faTrashAlt,
        iconBack: faBackward,
        hotKeys,
      }
    },
    methods: {
      deleteClicked () {
        this.$emit('delete')
      },
      submit (event) {
        console.log(arguments)
        event.target.click()
      }
    }
  }
</script>
<template>
  <b-row class="edit-header">
    <b-col cols="12">
      <b-button @click="$router.go(-1)">
        <font-awesome-icon :icon="iconBack" /> {{ $t('button.back') }}
      </b-button>
      <really-click
        v-if="showDelete"
        ref="reallyDelete"
        v-shortkey.once="hotKeys.delete"
        :disabled="!canDelete"
        :title="$t('button.delete')"
        :message="store + '.messages.delete-confirm'"
        variant="danger"
        class="float-right"
        @click="deleteClicked()"
        @shortkey.native="$refs['reallyDelete'].clicked()"
      >
        <font-awesome-icon :icon="iconDelete" /> {{ $t('button.delete') }}
      </really-click>
      <b-button
        v-shortkey.once="hotKeys.save"
        type="submit"
        class="btn btn-success float-right"
        @shortkey="submit"
      >
        <font-awesome-icon :icon="iconSave" /> {{ $t('button.save') }}
      </b-button>
      <slot />
    </b-col>
  </b-row>
</template>
