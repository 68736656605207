
export default function delete_ (/*resource, apiDefinition*/) {
  return {
    namespaced: true,
    actions: {
      del: ({ dispatch }, item) => {
        return new Promise((resolve, reject) => {
          dispatch('api/delete', {uri: item['@id']}, { root: true })
            .then(() => {
              resolve(item)
            })
            .catch((e) => {
              reject(e)
            })
        })
      },
    },
  }
}
