<script>
  import CreateView from '../partials/CreateView.vue'
  import FormInput from '../form/form-input.vue'
  import FormLookup from '../form/form-lookup.vue'

  export default {
    components: {
      CreateView,
      FormInput,
      FormLookup,
    },
    props: {
      bid: {
        type: String,
        required: true
      }
    },
    methods: {
      editBundle (bundle) {
        this.$router.push({name: 'BundleUpdate', params: {id: bundle.id}})
      },
      editDescriptor (descriptor) {
        this.$router.push({name: 'PackageDescriptorUpdate', params: {id: descriptor.id}})
      },
      init () {
        this.$store.dispatch('bundleconstraint/create/updateItem', {bundle: '/api/bundles/' + this.bid})
      }
    }
  }
</script>
<template>
  <create-view
    v-slot="slotProps"
    store="bundleconstraint"
    update-route="BundleConstraintUpdate"
    :init="init"
  >
    <form-lookup
      :store="slotProps.store"
      :translation-root="slotProps.translationRoot"
      prop-name="bundle"
      class="col-6"
      @edit="editBundle"
    >
      <template #selected-item="bundleProps">
        {{ bundleProps.item.name }}
      </template>
      <template #item="bundleProps">
        {{ bundleProps.item.name }}
      </template>
    </form-lookup>
    <form-lookup
      :store="slotProps.store"
      :translation-root="slotProps.translationRoot"
      prop-name="packageDescriptor"
      class="col-6"
      @edit="editDescriptor"
    >
      <template #selected-item="descriptorProps">
        {{ descriptorProps.item.name }}
      </template>
      <template #item="descriptorProps">
        {{ descriptorProps.item.name }}
      </template>
    </form-lookup>
    <form-input
      :store="slotProps.store"
      prop-name="constraintText"
      class="col-3"
    />
  </create-view>
</template>
