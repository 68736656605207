import deepFreeze from '../../util/deep-freeze.js'

export default function SwaggerDocs (docsJson) {
  if (!docsJson.swagger || docsJson.swagger !== '2.0') {
    throw new Error('Invalid swagger version: ' + docsJson.swagger)
  }
  const docObject = deepFreeze(docsJson)

  this.get = (path) => {
    let scope = docObject
    path.split('/').map((name, index, array) => {
      if (!scope[name]) {
        throw new Error(`Path not found: "${path}" undefined from position: ${index} on ("${array.slice(index).join('/')}")`)
      }
      scope = scope[name]
    })
    return scope
  }

  this.keys = (path) => {
    return Object.keys(this.get(path))
  }

  this.map = (path, callback) => {
    const object = this.get(path)
    return Object.keys(object).map((key, index) => callback(key, object[key], index))
  }

  this.$ref = (name) => {
    if (name.substring(0, 14) === '#/definitions/') {
      name = name.substring(14)
      try {
        return this.get('definitions/' + name)
      } catch (e) {
        throw new Error('Undefined reference: ' + name)
      }
    }
    throw new Error('Non relative references are not handled currently.')
  }

  /**
   * @param {{$ref: string}|{type: string}} schema
   * @return {{type}|undefined}
   */
  this.schema = (schema) => {
    if (schema === undefined) {
      return undefined;
    }

    // If referenced, take reference.
    if (schema['$ref']) {
      schema = this.$ref(schema['$ref'])
    }

    if (!schema.type) {
      throw new Error('No schema type given.')
    }

    // FIXME: special case we made up. :/
    if (schema.type === 'string' && schema.format && schema.format.substring(0, 14) === 'iri-reference<') {
      const reference = schema.format.slice(14, -1).toLowerCase();

      return {
        type: 'string',
        reference
      }
    }

    switch (schema.type) {
      // Literals.
      case 'boolean':
      case 'string':
      case 'integer':
      case 'date-time':
        return {type: schema.type}
      case 'object': {
        const result = {
          type: 'object'
        }

        result.properties = {}
        Object.keys(schema.properties || {}).map((propName) => {
          const property = this.schema(schema.properties[propName])
          property.readonly = !!schema.properties[propName].readOnly
          property.required = schema.required && schema.required.indexOf(propName) !== -1
          result.properties[propName] = property;
        })
        return result
      }
      case 'array': {
        const result = {
          type: 'array'
        }
        result.items = this.schema(schema.items);
        return result
      }
      default:
    }

    throw new Error('Unknown schema type: ' + schema.type)
  }

  this.parameters = (parameterList) => {
    const result = {
      filter: [],
      parameter: [],
    }
    if (parameterList === undefined) {
      return result
    }

    parameterList.map((parameter) => {
      if (parameter.in === 'body') {
        result.input = this.schema(parameter.schema)
        return
      }
      if (parameter.in === 'query') {
        const parsed = {
          name: parameter.name,
          required: parameter.required,
          ...this.schema(parameter),
        }
        if (Object.prototype.hasOwnProperty.call(parameter, 'default')) {
          parsed.default = parameter.default
        }
        result.filter.push(parsed)
        return
      }
      if (parameter.in === 'path') {
        result.parameter.push({
          name: parameter.name,
          required: parameter.required,
          ...this.schema(parameter),
        })
        return
      }
      throw new Error('Unknown location: ' + parameter.in)
    })
    return result
  }
}
