<script>
  import {
    BButton,
  } from 'bootstrap-vue'

  import {faSort, faSortUp, faSortDown} from '@fortawesome/free-solid-svg-icons'

  export default {
    components: {
      BButton,
    },
    props: {
      store: {
        type: String,
        required: true
      },
      propName: {
        type: String,
        required: true
      },
    },
    data () {
      return {
        iconSortAsc: faSortUp,
        iconSortDesc: faSortDown,
        iconSortUndef: faSort,
      }
    },
    computed: {
      direction () {
        return this.$store.getters[this.store + '/list/getSort'](this.propName)
      },
      enabled () {
        return this.$store.getters[this.store + '/list/isAllowedSort'](this.propName)
      },
      icon () {
        switch (this.direction) {
          case 'ASC':
            return this.iconSortAsc
          case 'DESC':
            return this.iconSortDesc
          default:
            return this.iconSortUndef
        }
      }
    },
    methods: {
      toggle () {
        this.$store.dispatch(this.store + '/list/toggleSort', this.propName)
      }
    }
  }
</script>
<template>
  <b-button
    size="sm"
    variant="link"
    text-decoration-none
    :disabled="!enabled"
    @click="toggle"
  >
    <strong>
      <slot />
      <font-awesome-icon
        v-if="enabled"
        :icon="icon"
        size="lg"
      />
    </strong>
  </b-button>
</template>
