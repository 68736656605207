<script>

  import {
    BCol,
  } from 'bootstrap-vue'

  import ListView from '../partials/ListView.vue'
  import ListSortToggler from '../partials/ListSortToggler.vue'

  export default {
    components: {
      BCol,
      ListView,
      ListSortToggler,
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
  }
</script>
<template>
  <list-view
    store="packagedescriptor_packageversiondescriptor"
    :list-parameters="{id}"
  >
    <template #header>
      <b-col md="10">
        <list-sort-toggler
          store="packagedescriptor_packageversiondescriptor"
          prop-name="version"
        >
          {{ $t('packagedescriptor_packageversiondescriptor.version.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template #item="slotProps">
      <b-col md="10">
        <div class="p-2">
          {{ slotProps.item.version }}
        </div>
      </b-col>
    </template>
  </list-view>
</template>

