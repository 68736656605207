<script>
  import {
    BToast,
  } from 'bootstrap-vue'

  import { mapGetters } from 'vuex'
  import TimeAgo from './TimeAgo.vue'

  export default {
    components: {
      BToast,
      TimeAgo
    },
    computed: {
      ...mapGetters({
        toasts: 'toasts/items'
      })
    },
    methods: {
      title (toast) {
        if (typeof toast.title === 'object') {
          return this.$t(toast.title.message, toast.title.parameters)
        }
        return toast.title
      },
      content (toast) {
        if (typeof toast.content === 'object') {
          return this.$t(toast.content.message, toast.content.parameters)
        }
        return toast.content
      }
    }
  }
</script>
<template>
  <div>
    <b-toast
      v-for="toast in toasts"
      :key="toast.id"
      :variant="toast.variant"
      solid
      no-auto-hide
      visible
      @hide="$store.dispatch('toasts/removeToast', toast)"
    >
      <div
        slot="toast-title"
        class="d-flex flex-grow-1 align-items-baseline"
      >
        <strong class="mr-auto">{{ title(toast) }}</strong>
        <small class="text-muted mr-2"><time-ago :time="toast.time" /></small>
      </div>
      {{ content(toast) }}
    </b-toast>
  </div>
</template>
