<script>
  import {timeDifference} from '../util/formatter.js'

  export default {
    props: {
      time: {type: [Date, String], default: undefined},
      minimal: {type: String, default: undefined},
    },
    data () {
      return {
        difference: timeDifference(new Date(), this.time, this.minimal),
      }
    },
    computed: {
      displayString () {
        return this.$tc('timediff.' + this.difference.type, this.difference[this.difference.type])
      }
    },
    mounted () {
      this.interval = window.setInterval(() => {
        this.difference = timeDifference(new Date(), this.time, this.minimal)
      }, 5000)
    },
    beforeDestroy () {
      window.clearInterval(this.interval)
      this.interval = null
    }
  }
</script>
<template>
  <span>{{ displayString }}</span>
</template>
