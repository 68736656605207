<script>
  import EditView from '../partials/EditView.vue'
  import FormInput from '../form/form-input.vue'
  import FormLookup from '../form/form-lookup.vue'
  import FormRepositoryConfig from '../form/form-repository-config.vue'

  export default {
    components: {
      EditView,
      FormInput,
      FormLookup,
      FormRepositoryConfig,
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    methods: {
      editAuth (item) {
        this.$router.push({name: 'PackageDescriptorAuthUpdate', params: {id: item.id}})
      }
    }
  }
</script>
<template>
  <edit-view
    :id="id"
    v-slot="slotProps"
    store="packagedescriptor"
    list-route="PackageDescriptorList"
  >
    <form-input
      :store="slotProps.store"
      prop-name="name"
      class="col-6"
    />
    <form-lookup
      :store="slotProps.store"
      :translation-root="slotProps.translationRoot"
      prop-name="originRepositoryAuth"
      class="col-6"
      :edit-button="true"
      @edit="editAuth"
    >
      <template #selected-item="authProps">
        {{ authProps.item.name }}
      </template>
      <template #item="authProps">
        {{ authProps.item.name }}
      </template>
    </form-lookup>
    <form-repository-config
      :store="slotProps.store"
      prop-name="originRepositoryConfig"
      prop-name-type="originRepositoryType"
      prop-name-url="originRepositoryUrl"
      class="col-12"
    />
  </edit-view>
</template>
