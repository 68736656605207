<script>
  import CreateView from '../partials/CreateView.vue'
  import FormInput from '../form/form-input.vue'

  export default {
    components: {
      CreateView,
      FormInput,
    },
  }
</script>
<template>
  <create-view
    v-slot="slotProps"
    store="bundle"
    update-route="BundleUpdate"
  >
    <form-input
      :store="slotProps.store"
      prop-name="name"
      class="col-3"
    />
  </create-view>
</template>
