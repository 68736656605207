import Vue from 'vue'

function mergeArray (baseValue, value) {
  for (let i = 0; i < value.length; i++) {
    let val = merge(baseValue[i], value[i]);
    if (val === undefined) {
      continue
    }
    if (baseValue[i]) {
      Vue.set(baseValue, i, val)
    } else {
      baseValue.push(val)
    }
  }
}

function mergeObject (baseValue, value) {
  for (let key of Object.keys(value)) {
    if (Object.prototype.hasOwnProperty.call(value, key) && undefined === value[key]) {
      baseValue[key] = undefined
      continue
    }
    baseValue[key] = Object.prototype.hasOwnProperty.call(baseValue, key) ? merge(baseValue[key], value[key]) : value[key]
  }
}

function sameType (a, b) {
  if (a === undefined || b === undefined) {
    return true
  }
  const arrA = Array.isArray(a)
  const arrB = Array.isArray(b)
  const typeA = typeof a
  const typeB = typeof b

  if (arrA || arrB) {
    if (arrA && arrB) {
      return true
    }
    throw new Error(`Invalid type mixing a: ${arrA ? 'array' : typeA} b: ${arrB ? 'array' : typeB}`)
  }

  if ((typeof a === typeof b)) {
    return true
  }
  throw new Error(`Invalid type mixing a: ${typeof a} b: ${typeof b}`)
}

export const merge = (baseValue, value) => {
  if (value === undefined) {
    return baseValue
  }
  // If either is null, use the value to allow overwriting with null.
  if (value === null || baseValue === null) {
    return value
  }
  sameType(baseValue, value)
  if (Array.isArray(baseValue) && Array.isArray(value)) {
    mergeArray(baseValue, value)
  } else if (value && baseValue && typeof value === 'object' && typeof baseValue === 'object') {
    mergeObject(baseValue, value)
  } else {
    return value
  }
  return baseValue
}

export default merge
