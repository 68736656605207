<script>
  import router from '../router.js'

  export default {
    data () {
      return {
        interval: null,
        now: Math.trunc((new Date()).getTime() / 1000)
      }
    },
    computed: {
      date () {
        const decoded = this.$store.getters['authentication/expiresAt']
        if (!decoded) {
          return null
        }
        return (new Date(decoded * 1000)) / 1000
      },
      logoutTime () {
        if (this.date === null) {
          return ''
        }
        const diff = this.date - this.now

        return this.twoDigits(Math.trunc((diff) / 60 / 60 / 24)) + ':' +
          this.twoDigits(Math.trunc((diff) / 60 / 60) % 24) + ':' +
          this.twoDigits(Math.trunc(diff / 60) % 60) + ':' +
          this.twoDigits(diff  % 60)
      }
    },
    mounted () {
      this.interval = window.setInterval(() => {
        this.now = Math.trunc((new Date()).getTime() / 1000)
        if (this.date === null) {
          return
        }
        const diff = this.date - this.now
        if (diff < 60) {
          this.$store.dispatch('authentication/refresh').catch(() => {
            router.push({name: 'LoginPage'})
          })
        }
      }, 1000)
    },
    beforeDestroy () {
      window.clearInterval(this.interval)
      this.interval = null
    },
    methods: {
      twoDigits (number) {
        if (number < 10) return '0' + number
        else return number
      }
    }
  }
</script>
<template>
  <span>Logout in {{ logoutTime }}</span>
</template>
