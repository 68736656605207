<script>

  import {
    BButton,
    BCol,
    BContainer,
    BListGroup,
    BListGroupItem,
    BRow,
  } from 'bootstrap-vue'

  import {faTrashAlt, faEdit, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
  import FilterHeader from './FilterHeader.vue'
  import ReallyClick from '../ReallyClick.vue'

  export default {
    components: {
      BButton,
      BListGroup,
      BListGroupItem,
      BCol,
      BContainer,
      BRow,
      FilterHeader,
      ReallyClick
    },
    props: {
      store: {
        type: String,
        required: true
      },
      addRoute: {
        type: [String, Function, null],
        default: null
      },
      editRoute: {
        type: [String, Function, null],
        default: null
      },
      init: {
        type: Function,
        default: undefined
      },
      listParameters: {
        type: [Object],
        default: undefined
      }
    },
    data () {
      return {
        iconEdit: faEdit,
        iconDelete: faTrashAlt,
        iconError: faExclamationTriangle,
      }
    },
    computed: {
      error () {
        return this.$store.getters[this.store + '/list/error']
      },
      items () {
        return this.$store.getters[this.store + '/list/items']
      },
    },
    mounted () {
      if (this.init) {
        this.init().then(() => {
          this.getPage()
        })
        return
      }
      this.getPage()
    },
    methods: {
      getPage () {
        this.$store.dispatch(this.store + '/list/default', {parameters: this.listParameters})
      },
      deleteRow(item) {
        this.$store.dispatch(this.store + '/del/del', item)
          .then(
            (item) => {
              this.$store.dispatch('toasts/addToast', {
                variant: 'success',
                title: {message: `${this.store}.toasts.delete-success.title`, parameters: {...item}},
                content: {message: `${this.store}.toasts.delete-success.content`, parameters: {...item}},
                hideAfter: 5,
              })
              this.getPage()
            },
            (error) => {
              this.$store.dispatch('toasts/addToast', {
                variant: 'danger',
                title: {message: `${this.store}.toasts.delete-fail.title`, parameters: {error, ...item}},
                content: {message: `${this.store}.toasts.delete-fail.content`, parameters: {error, ...item}},
              }, { root: true })
            }
          )
      },
      editRouteTo (item) {
        if (null === this.editRoute) {
          return null
        }
        if (typeof this.editRoute === 'string') {
          return {name: this.editRoute, params: { id: item.id }}
        }

        return this.editRoute(item)
      },
      addRouteTo () {
        if (null === this.addRoute) {
          return null
        }
        if (typeof this.addRoute === 'string') {
          return {name: this.addRoute}
        }

        return this.addRoute()
      }
    }
  }
</script>
<template>
  <b-container fluid>
    <filter-header
      :store-namespace="store"
      :add-to="addRouteTo()"
    >
      <template #default>
        <slot name="filter" />
      </template>
    </filter-header>
    <b-list-group>
      <b-list-group-item>
        <b-row>
          <slot name="header">
            <b-col md="10">
              <strong>Item</strong>
            </b-col>
          </slot>
          <b-col md="2" />
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-for="(item, index) in items"
        :key="item['@id']"
        :variant="index % 2 ? 'dark' : 'light'"
      >
        <b-row>
          <slot
            name="item"
            :item="item"
          >
            <b-col md="10">
              {{ item }}
            </b-col>
          </slot>
          <b-col
            md="2"
            class="text-right"
          >
            <slot
              name="buttons"
              :item="item"
            />
            <b-button
              v-if="editRouteTo(item) !== null"
              type="button"
              :title="$t('button.edit')"
              variant="success"
              :to="editRouteTo(item)"
            >
              <font-awesome-icon :icon="iconEdit" />
            </b-button>
            <really-click
              :title="$t('button.delete')"
              :message="store + '.messages.delete-confirm'"
              variant="danger"
              @click="deleteRow(item)"
            >
              <font-awesome-icon :icon="iconDelete" />
            </really-click>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-container>
</template>
