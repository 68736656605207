import {faRandom} from '@fortawesome/free-solid-svg-icons'

export default {
  data () {
    return {
      iconRandom: faRandom
    }
  },
  methods: {
    random () {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 64; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }

      return result
    }
  }
}
