<script>

import {
  BButton,
  BCol,
  BFormGroup,
  BTooltip,
} from 'bootstrap-vue'

import ListView from '../partials/ListView.vue'
import ListSortToggler from '../partials/ListSortToggler.vue'
import PackageDescriptor from '../links/PackageDescriptor.vue'
import LinkToRepository from '../links/LinkToRepository.vue'
import TimeAgo from '../TimeAgo.vue'

import {faEye, faEyeSlash, faExclamationTriangle, faBomb, faInfo, faBell} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    BButton,
    BCol,
    BFormGroup,
    BTooltip,
    ListView,
    ListSortToggler,
    PackageDescriptor,
    LinkToRepository,
    TimeAgo
  },
  data () {
    return {
      iconMarkRead: faEye,
      iconIsRead: faEyeSlash,
      iconInfo: faInfo,
      iconWarning: faExclamationTriangle,
      iconError: faBomb,
      iconUnknown: faBell,
    }
  },
  computed: {
    showMarkedAsRead: {
      get() {
        /*
          Mapping is:
            Textual            | store value | button state
            Show read messages | null        | true
            Hide read messages | undefined   | false
         */
        const current = this.$store.getters['notification/list/filter'].read
        return current === null
      },
      set (read) {
        const desired = true === read ? null : undefined
        this.$store.dispatch('notification/list/setFilter', {read: desired})
      }
    }
  },
  methods: {
    markRead (item) {
      this.$store
        .dispatch('api/put', {uri: item['@id'], options: {body: JSON.stringify({read: true})}})
        .then(() => {
          return this.reload()
        });
    },
    packageInfo (item) {
      return this.$store.getters['notification/list/ref_package'](item.package);
    },
    repositoryInfo (item) {
      return this.$store.getters['notification/list/ref_repository'](item.repository);
    },
    reload() {
      return this.$refs.list.getPage()
    },
    messageIcon(type) {
      switch (type) {
        case 'info':
          return this.iconInfo
        case 'warning':
          return this.iconWarning
        case 'error':
          return this.iconError
      }
      return this.iconUnknown
    }
  }
}
</script>
<template>
  <list-view
    ref="list"
    store="notification"
  >
    <template #filter>
      <b-form-group
        class="mb-2 mr-sm-2 mb-sm-0"
      >
        <b-button :pressed.sync="showMarkedAsRead">
          {{ showMarkedAsRead ? 'Hide read' : 'Show read' }}
        </b-button>
      </b-form-group>
    </template>
    <template #header>
      <b-col md="1">
        <list-sort-toggler
          store="notification"
          prop-name="createdDate"
        >
          {{ $t('notification.creationDate.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col md="4">
        <list-sort-toggler
          store="notification"
          prop-name="reference"
        >
          {{ $t('notification.reference.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col md="5">
        <list-sort-toggler
          store="notification"
          prop-name="message"
        >
          {{ $t('notification.message.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template #item="slotProps">
      <b-col
        :id="'fetch-date-'+slotProps.item.id"
        md="1"
      >
        <font-awesome-icon
          :icon="messageIcon(slotProps.item.severity)"
          size="2x"
        />
        <div
          class="p-2 d-inline-block"
        >
          {{ $d(new Date(slotProps.item.creationDate), 'short') }}
        </div>
      </b-col>
      <b-tooltip :target="'fetch-date-'+slotProps.item.id">
        <time-ago
          :time="slotProps.item.creationDate"
          minimal="days"
        />
      </b-tooltip>
      <b-col md="4">
        <div v-if="slotProps.item.repository">
          <link-to-repository :repository="repositoryInfo(slotProps.item)" />
        </div>
        <div v-if="slotProps.item.package">
          <package-descriptor :package-descriptor="packageInfo(slotProps.item)" />
        </div>
      </b-col>
      <b-col
        md="5"
        class="p-2"
      >
        {{ slotProps.item.message }}
      </b-col>
    </template>
    <template #buttons="slotProps">
      <b-button
        :title="$t(slotProps.item.read ? 'notification.button.is_read' : 'notification.button.read')"
        :disabled="slotProps.item.read"
        variant="success"
        @click="markRead(slotProps.item)"
      >
        <font-awesome-icon :icon="slotProps.item.read ? iconIsRead : iconMarkRead" />
      </b-button>
    </template>
  </list-view>
</template>

