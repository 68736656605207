<script>
  import EditView from '../partials/EditView.vue'
  import FormInput from '../form/form-input.vue'
  import {
    BFormCheckboxGroup,
    BFormGroup,
  } from 'bootstrap-vue'

  export default {
    components: {
      BFormGroup,
      BFormCheckboxGroup,
      EditView,
      FormInput,
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    computed: {
      bundles () {
        return this.$store.getters['repository/update/bundles_options']
      },
      selectedBundles: {
        get () {
          return this.$store.getters['repository/update/item']['bundles']
        },
        set (items) {
          this.$store.dispatch(`repository/update/updateItem`, {bundles: items})
        }
      }
    },
  }
</script>
<template>
  <edit-view
    :id="id"
    v-slot="slotProps"
    store="repository"
    list-route="RepositoryList"
  >
    <form-input
      :store="slotProps.store"
      prop-name="name"
      class="col-3"
    />
    <b-form-group
      :label="$t('repository.bundles.label')"
      :description="$t('repository.bundles.description')"
      class="col-12"
    >
      <b-form-checkbox-group
        v-model="selectedBundles"
        :options="bundles"
        value-field="@id"
        text-field="name"
        switches
        stacked
      />
    </b-form-group>
  </edit-view>
</template>
