<script>

  import {
    BButton,
    BCol,
  } from 'bootstrap-vue'

  import ListView from '../partials/ListView.vue'
  import ListSortToggler from '../partials/ListSortToggler.vue'

  import {faTicketAlt, faBoxOpen} from '@fortawesome/free-solid-svg-icons'

  export default {
    components: {
      BButton,
      BCol,
      ListView,
      ListSortToggler,
    },
    data () {
      return {
        iconToken: faTicketAlt,
        iconPackages: faBoxOpen,
      }
    }
  }
</script>
<template>
  <list-view
    store="bundle"
    add-route="BundleCreate"
    edit-route="BundleUpdate"
  >
    <template #header>
      <b-col md="10">
        <list-sort-toggler
          store="bundle"
          prop-name="name"
        >
          {{ $t('repository.name.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template #item="slotProps">
      <b-col
        md="10"
        class="p-2"
      >
        {{ slotProps.item.name }}
      </b-col>
    </template>
    <template #buttons="slotProps">
      <b-button
        :to="{name: 'BundleConstraintList', params: {bid: slotProps.item.id}}"
        :title="$t('bundle.button.constraints')"
        variant="secondary"
      >
        <font-awesome-icon :icon="iconPackages" />
      </b-button>
    </template>
  </list-view>
</template>

