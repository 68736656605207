<script>
  import formFieldContainerMixin from './form-field-container-mixin.js'

  import {
    BFormGroup,
    BFormInput,
    BFormSelect,
  } from 'bootstrap-vue'

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BFormSelect,
    },
    mixins: [
      formFieldContainerMixin
    ],
    props: {
      propNameType: {
        type: String,
        required: true
      },
      propNameUrl: {
        type: String,
        required: true
      },
    },
    computed: {
      isReadOnly () {
        return this.typeIsReadOnly || this.urlIsReadOnly
      },
      isInvalid () {
        return this.typeIsInvalid || this.urlIsInvalid
      },
      isRequired () {
        return this.typeIsRequired || this.urlIsRequired
      },
      typeValue () {
        return this.$store.getters[this.store + '/item'][this.propNameType]
      },
      typeOptions () {
        return ['vcs'].map(type => {
          // FIXME: translations.
          return {
            value: type,
            text: this.$t(`${this.translations}.${this.propNameType}.options.${type}`)
          }
        })
      },
      typeLabel () {
        return this.$t(`${this.translations}.${this.propNameType}.label`)
      },
      typeDescription () {
        return this.$t(`${this.translations}.${this.propNameType}.description`)
      },
      typeViolations () {
        const violations = this.$store.getters[this.store + '/violations'] || {}
        if (!violations || !violations[this.propNameType]) {
          return null
        }

        const translationKey = violations[this.propNameType].replace(/\./g, '').replace(/[^a-zA-Z0-9]/g, '-')

        if (this.$te(`${this.translations}.${this.propNameType}.errors.${translationKey}`)) {
          return this.$t(`${this.translations}.${this.propNameType}.errors.${translationKey}`)
        }

        return this.$t('errors.' + translationKey)
      },
      typeIsInvalid () {
        const violations = this.$store.getters[this.store + '/violations'] || {}

        return violations && violations[this.propNameType]
      },
      typeIsReadOnly () {
        return this.$store.getters[this.store + '/readOnly'](this.propNameType)
      },
      typeIsRequired () {
        return this.$store.getters[this.store + '/required'](this.propNameType)
      },

      urlValue () {
        return this.$store.getters[this.store + '/item'][this.propNameUrl]
      },
      urlLabel () {
        return this.$t(`${this.translations}.${this.propNameUrl}.label`)
      },
      urlDescription () {
        return this.$t(`${this.translations}.${this.propNameUrl}.description`)
      },
      urlViolations () {
        const violations = this.$store.getters[this.store + '/violations'] || {}
        if (!violations || !violations[this.propNameUrl]) {
          return null
        }

        const translationKey = violations[this.propNameUrl].replace(/\./g, '').replace(/[^a-zA-Z0-9]/g, '-')

        if (this.$te(`${this.translations}.${this.propNameUrl}.errors.${translationKey}`)) {
          return this.$t(`${this.translations}.${this.propNameUrl}.errors.${translationKey}`)
        }

        return this.$t('errors.' + translationKey)
      },
      urlIsInvalid () {
        const violations = this.$store.getters[this.store + '/violations'] || {}

        return violations && violations[this.propNameUrl]
      },
      urlIsReadOnly () {
        return this.$store.getters[this.store + '/readOnly'](this.propNameUrl)
      },
      urlIsRequired () {
        return this.$store.getters[this.store + '/required'](this.propNameUrl)
      },
    },
    methods: {
      updateType (type) {
        this.$store.dispatch(`${this.store}/updateItem`, {[this.propNameType]: type})
      },
      updateUrl (type) {
        this.$store.dispatch(`${this.store}/updateItem`, {[this.propNameUrl]: type})
      },
    },
  }
</script>
<template>
  <b-form-group
    :description="description"
    :label="label"
    :state="!isInvalid"
    :disabled="isReadOnly"
    :label-class="labelClasses + 'pt-0'"
    class="mb-0"
  >
    <b-form-group
      :description="typeDescription"
      :label="typeLabel"
      :label-for="propName + '.type'"
      :invalid-feedback="typeViolations"
      :state="!typeIsInvalid"
      :disabled="typeIsReadOnly"
      :label-class="labelClasses + 'pt-0'"
      label-cols-sm="1"
      label-align-sm="right"
    >
      <b-form-select
        :id="propName + '.type'"
        :value="typeValue"
        :options="typeOptions"
        @input="updateType($event)"
      />
    </b-form-group>
    <b-form-group
      :description="urlDescription"
      :label="urlLabel"
      :label-for="propName + '.url'"
      :invalid-feedback="urlViolations"
      :state="!urlIsInvalid"
      :disabled="urlIsReadOnly"
      :label-class="labelClasses + 'pt-0'"
      label-cols-sm="1"
      label-align-sm="right"
    >
      <b-form-input
        :id="propName + '.url'"
        :value="urlValue"
        @input="updateUrl($event)"
      />
    </b-form-group>
  </b-form-group>
</template>
