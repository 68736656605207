import merge from '../../util/merge-object.js'
import SwaggerDocs from './swagger-docs.js'
import fetchDocs from './fetch-docs.js'

/**
 * @param {object} resourceDefinition
 * @param {SwaggerDocs} swaggerDocs
 * @param {object} operation
 * @param {string} method
 * @param {string} baseUrl
 * @param {string} pathName
 */
function parseOperation(resourceDefinition, swaggerDocs, operation, method, baseUrl, pathName) {
  let crud_type;
  let input;
  let output;
  input = operation.parameters
  switch (method) {
    case 'post':
      crud_type = 'create';
      if (operation.responses['201'] && operation.responses['201'].schema) {
        output = operation.responses['201'].schema
        break;
      }
      if (operation.responses['200'] && operation.responses['200'].schema) {
        output = operation.responses['200'].schema
        break;
      }
      return;
    case 'get':
      if (operation.responses['200'] && operation.responses['200'].schema) {
        output = operation.responses['200'].schema
        if (output.type === 'array') {
          crud_type = 'list';
          break;
        }
        crud_type = 'show';
        break;
      }
      return;
    case 'put':
      if (operation.responses['200'] && operation.responses['200'].schema) {
        crud_type = 'update';
        if (operation.responses['200']) {
          output = operation.responses['200'].schema
          break;
        }
        break;
      }
      return;
    case 'delete':
      crud_type = 'delete';
      break;
  }

  merge(resourceDefinition.operations, {
    [crud_type]: {
      url_absolute: baseUrl + pathName,
      url: pathName,
      method: method,
      ...swaggerDocs.parameters(input),
      ...((operation.produces && -1 !== operation.produces.indexOf('application/json')) ? {output: swaggerDocs.schema(output)} : {}),
    }
  })
}

export function parse (apiDefinition, swagger) {
  const swaggerDocs = new SwaggerDocs(swagger)
  if (!apiDefinition.baseUrl) {
    throw new Error('Missing baseUrl.')
  }
  if (!apiDefinition.resource) {
    apiDefinition.resource = {}
  }

  const subResources = {}
  // Loop over paths
  swaggerDocs.map('paths', (pathName, pathInfo) => {
    Object.keys(pathInfo).map(httpMethod => {
      const operation = pathInfo[httpMethod]
      // By convention, we expect the resource name as first tag.
      if (!operation.tags || operation.tags.length === 0) {
        throw new Error('Missing resource name as first tag.')
      }
      if (!operation.responses || Object.keys(operation.responses).length === 0) {
        throw new Error('Missing response definitions.')
      }
      let resourceName = operation.tags[0].toLowerCase();
      // sub resources have to be handled differently.
      if (operation.tags[0].length > 1 && operation.operationId && operation.operationId.indexOf('subresource') !== -1) {
        const subResourcePath = operation.tags.map(tag => tag.toLowerCase()).reverse()
        let tmp = subResources
        subResourcePath.map(subResource => {
          if (tmp[subResource] === undefined) {
            tmp[subResource] = {}
          }
          tmp = tmp[subResource]
        })
        resourceName = subResourcePath.join('_')
      }

      apiDefinition.resource[resourceName] = merge(apiDefinition.resource[resourceName] || {}, {
        name: resourceName,
        operations: {}
      })
      parseOperation(apiDefinition.resource[resourceName], swaggerDocs, operation, httpMethod, apiDefinition.baseUrl, pathName)
    })
  })
  // API platform only supports list operations on sub resources, we therefore have to copy the missing CUD operations
  // over from the root store.
  // FIXME: this is a rather evil hack for the fact that we do not have separation of delete handling in list view etc.
  const cloneOperationsForSubResource = (resource, stack) => {
    for (let [subResource, subResources] of Object.entries(resource)) {
      const newStack = stack.concat([subResource])
      cloneOperationsForSubResource(subResources, newStack)

      const combinedSubresource = newStack.join('_')

      Object.keys(apiDefinition.resource[subResource].operations).forEach(opName => {
        // Copy the operations from the root resource
        if (!apiDefinition.resource[combinedSubresource].operations[opName]) {
          apiDefinition.resource[combinedSubresource].operations[opName] = apiDefinition.resource[subResource].operations[opName]
        }
      })
    }
  }
  cloneOperationsForSubResource(subResources, [])
}

function parseSwagger (apiDefinition) {
  if (!apiDefinition.entrypoint) {
    throw new Error('API entrypoint missing - can not load docs')
  }

  if (!apiDefinition.baseUrl) {
    const url = new URL(apiDefinition.entrypoint)
    apiDefinition.baseUrl = `${url.protocol}//${url.host}`
    if (url.protocol.substr(0, 4) !== 'http') {
      throw new Error('Unknown protocol: ' + url.protocol)
    }
    if ((url.protocol === 'http' && url.port !== '80') || (url.protocol === 'https' && url.port !== '443')) {
      apiDefinition.baseUrl += ':' + url.port
    }
  }

  return fetchDocs(apiDefinition.baseUrl + '/api/docs.json')
    .then((response) => response.json())
    .then((response) => {
      parse(apiDefinition, response)
      return apiDefinition
    })
}

export default parseSwagger
