const ADD_TOAST = 'ADD_TOAST'
const REMOVE_TOAST = 'REMOVE_TOAST'
const UPDATE_TOASTS = 'UPDATE_TOASTS'

let updater = {
  handle: null
}

export default {
  namespaced: true,
  state: {
    items: [],
  },
  actions: {
    addToast: ({commit}, toast) => {
      if (!updater.handle) {
        updater.handle = window.setInterval(() => {
          commit(UPDATE_TOASTS)
        }, 1000)
      }

      commit(ADD_TOAST, toast)
    },
    removeToast: ({commit, getters}, toast) => {
      commit(REMOVE_TOAST, toast)
      if (getters.items.length === 0 && updater.handle) {
        window.clearInterval(updater.handle)
        updater.handle = null
      }
    }
  },
  getters: {
    items: (state) => state.items
  },
  mutations: {
    [ADD_TOAST] (state, toast) {
      if (toast.time === undefined) {
        toast.time = new Date()
      }
      if (toast.hideAfter !== undefined) {
        toast.removeTime = Date.now() + toast.hideAfter * 1000
      }
      toast.id = Date.now()
      state.items = state.items.concat([toast])
    },
    [REMOVE_TOAST] (state, toast) {
      state.items = state.items.filter(test => test !== toast)
    },
    [UPDATE_TOASTS] (state) {
      const now = Date.now()
      state.items = state.items.filter((toast) => {
        return !toast.removeTime || (toast.removeTime > now)
      })
    }
  }
}
