import formFieldContainerMixin from './form-field-container-mixin.js'

export default {
  mixins: [formFieldContainerMixin],
  computed: {
    violations () {
      const violations = this.$store.getters[this.store + '/violations'] || {}
      if (!violations || !violations[this.propName]) {
        return null
      }

      const translationKey = violations[this.propName].replace(/\./g, '').replace(/[^a-zA-Z0-9]/g, '-')

      if (this.$te(`${this.translations}.${this.propName}.errors.${translationKey}`)) {
        return this.$t(`${this.translations}.${this.propName}.errors.${translationKey}`)
      }

      return this.$t('errors.' + translationKey)
    },
    isInvalid () {
      const violations = this.$store.getters[this.store + '/violations'] || {}

      return violations && violations[this.propName]
    },
    isReadOnly () {
      return this.$store.getters[this.store + '/readOnly'](this.propName)
    },
    isRequired () {
      return this.$store.getters[this.store + '/required'](this.propName)
    },
    value () {
      return this.$store.getters[this.store + '/item'][this.propName]
    },
    inputName () {
      return `${this.translations}.${this.propName}`
    },
    autocomplete () {
      // See: https://bugs.chromium.org/p/chromium/issues/detail?id=587466
      switch (this.type) {
        case 'password':
          return 'new-password'
        case 'text':
          return 'off-' + Math.random()
      }
    },
  },
  methods: {
    updateField (value) {
      if (value !== this.value) {
        this.$store.dispatch(this.store + '/updateItem', { [this.propName]: value })
      }
    }
  },
}
