import jwtDecode from 'jwt-decode'

import router from '../../router.js'

export default (apiDefinition) => {
  if (!apiDefinition.resource.authenticate) {
    throw new Error('No authentication resource')
  }
  if (!apiDefinition.resource.authenticate.operations.create) {
    throw new Error('No authentication create operation')
  }
  if (!apiDefinition.resource.authenticate.operations.show) {
    throw new Error('No authentication show operation - needed for token refresh')
  }
  const storageKey = 'token-' + apiDefinition.resource.authenticate.operations.create.url
  const token = localStorage.getItem(storageKey);

  return {
      namespaced: true,
      state: {
        user: token ? jwtDecode(token) : null,
        token,
      },
      actions: {
        login ({dispatch, commit}, {username, password} = {}) {
          dispatch('api/fetch', {
            uri: apiDefinition.resource.authenticate.operations.create.url,
            options: {
              method: 'POST',
              // FIXME: build request body from schema.
              body: JSON.stringify({username, password})
            }
          }, {root: true})
            .then((response) => {
              return response.json()
            })
            .then(
              body => {
                return body.token
              }
            )
            .then(
              data => {
                dispatch('updateToken', data);
                router.back();
              },
              error => {
                commit('loginFailure', error);
              }
            );
        },
        refresh ({dispatch, commit, getters}) {
          if (!getters.token) {
            dispatch('logout');
            return
          }
          // FIXME: build request URL.
          dispatch('api/fetch', {uri: apiDefinition.resource.authenticate.operations.show.url}, {root: true})
            .then((response) => {
              return response.json()
            })
            .then(
              body => {
                return body.token
              }
            )
            .then(
              data => {
                dispatch('updateToken', data);
              },
              error => {
                commit('loginFailure', error);
                dispatch('logout');
              }
            );
        },
        updateToken ({commit}, token) {
          commit('loginSuccess', token);
        },
        logout ({commit}) {
          commit('logout');
          if (router.currentRoute.name !== 'LoginPage') {
            router.push({name: 'LoginPage'})
          }
        }
      },
      getters: {
        token: state => state.token,
        username: state => state.user ? state.user.username : undefined,
        userId: state => state.user ? state.user.id : undefined,
        expiresAt: state => state.user ? state.user.exp : undefined,
      },
      mutations: {
        loginSuccess (state, token) {
          state.user = jwtDecode(token);
          state.token = token;
          localStorage.setItem(storageKey, token)
        },
        loginFailure (state) {
          state.user = null;
          localStorage.removeItem(storageKey)
        },
        logout (state) {
          state.user = null;
          localStorage.removeItem(storageKey)
        },
      }
    }
}
