<script>
  import {
    BContainer,
    BListGroup,
    BListGroupItem
  } from 'bootstrap-vue'

  export default {
    components: {
      BContainer,
      BListGroup,
      BListGroupItem
    },
    data () {
      return {
        stats: {}
      }
    },
    mounted () {
      this.$store.dispatch('authentication/refresh')
      this.$store.dispatch('api/fetch', {uri: '/api/statistics.json'})
        .then(response => response.json())
        .then((result) => {
          this.stats = result
        })
    }
  }
</script>
<template>
  <b-container fluid>
    <b-list-group col="6">
      <b-list-group-item>Repositories: {{ stats.repositories }}</b-list-group-item>
      <b-list-group-item>Bundles: {{ stats.bundles }}</b-list-group-item>
      <b-list-group-item>Packagedescriptors: {{ stats.packagedescriptors }}</b-list-group-item>
      <b-list-group-item>Packageversiondescriptors: {{ stats.packageversiondescriptors }}</b-list-group-item>
      <b-list-group-item>Jobs: {{ stats.jobs }}</b-list-group-item>
    </b-list-group>
  </b-container>
</template>
